import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Radio,
  Table,
  Dropdown,
  Input,
  Select,
  Tooltip,
  Checkbox,
  message,
} from "antd";
import infoIcon from "../../../Assets/advert_detail/infoIcon.svg";
import SearchIcon from "../../../Assets/campagin/searchIcon.svg";
import CancelIcon from "../../../Assets/cancelIcon.svg";
import FilterIcon from "../../../Assets/filter.svg";
import editIcon from "../../../Assets/campagin/edit.svg";
import downloadIcon from "../../../Assets/advert_detail/download.svg";
import deleteRed from "../../../Assets/advert_detail/delete_icon_red.svg";
import verticleDot from "../../../Assets/dots-vertical.svg";
import statusBlue from "../../../Assets/advert_detail/status.svg";
import { useNavigate } from "react-router-dom";
import EditAddMessageModal from "./EditAddModal";
import UpgradeMembershipModal from "../UpgradeMembershipPopup";
import API_MANAGER from "../../../API";
import { downloadPDF } from "../../../utils/constant";
import {
  CommonDeleteSvg,
  DownloadIcon,
  TableStatusImage,
} from "../../../Assets/campagin/sectionProgress/svgs";
import CustomPagination from "../CustomPagination";
function Notes({
  data2,
  advertId,
  handleStatus,
  setSelectedRowData,
  handleDelete,
  setRowKey,
  getData,
  tableActionItems,
  setCandidateSelectedData,
  // setCurrentPage,
  // currentPage,
  handleDeleteFunctionality,
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterType, setFilterType] = useState("all");
  const [messageModal, setMessageModal] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState();
  const [upgradeMembership, setUpgradeMembership] = useState(false);
  const [data, setData] = useState(data2?.users);
  const [columns, setColumns] = useState([]);
  const [reportLoading, setReportLoading] = useState(false);
  const [fullyMeets, setFullyMeets] = useState(0);
  const [partiallyMeets, setPartiallyMeets] = useState(0);
  const [doesNotMeets, setDoesNotMeets] = useState(0);
  const user = localStorage.getItem("user");
  const [search, setSearch] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const dataperpage = 10;

  const paginatedData = data?.slice(
    (currentPage - 1) * dataperpage,
    currentPage * dataperpage
  );

  const navigate = useNavigate();

  const handleViewMore = (rawData, type) => {
    setSelectedMessage({ ...rawData, ["type"]: type });
    setMessageModal(true);
    // setButtonVisible(false);
  };
  const handleEditMessage = (row, type) => {
    setSelectedMessage({ ...row, ["type"]: type });
    setMessageModal(true);
    setButtonVisible(true);
  };
  const handleSorting = async (e) => {
    // let tempData = [];
    if (e === "az") {
      let tempData = await data2?.users?.sort(function (a, b) {
        if (a?.profileId?.name < b?.profileId?.name) {
          return -1;
        }
        if (a?.profileId?.name > b?.profileId?.name) {
          return 1;
        }
        return 0;
      });
      setData([]);
      setData(tempData);
    } else if (e === "za") {
      let tempData = await data2?.users?.sort(function (a, b) {
        if (a?.profileId?.name < b?.profileId?.name) {
          return 1;
        }
        if (a?.profileId?.name > b?.profileId?.name) {
          return -1;
        }
        return 0;
      });
      setData([]);
      setData(tempData);
    }
  };
  const items = [
    {
      key: "1",
      label: (
        <Radio.Group
          className="dropDown_radio_group"
          onChange={(e) => handleSorting(e?.target?.value)}
        >
          <Row className="filter_item_row">
            <Col span={24}>
              <Radio value={"az"}>Sort by A-Z</Radio>
            </Col>
          </Row>
          <Row className="filter_item_row">
            <Col span={24}>
              <Radio value={"za"}>Sort by Z-A</Radio>
            </Col>
          </Row>
        </Radio.Group>
      ),
    },
  ];
  const handleBulkAcceptRejectCandidate = async () => {
    try {
      const params = {
        isApproved: "accepted",
        ajIds: [...selectedRowKeys],
      };
      await API_MANAGER.bulkAcceptRejectCandidate(params);
      message.success("Application accepted successfully.");
      getData();
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    if (data2?.users?.length > 0) {
      let tempFullyMeet = 0;
      let tempDoesNotMeet = 0;
      let tempPartiallyMeet = 0;
      data2?.users?.map((item) => {
        if (item?.overAllStatus === "Yes") {
          tempFullyMeet = tempFullyMeet + 1;
        } else if (item?.overAllStatus === "No") {
          tempDoesNotMeet = tempDoesNotMeet + 1;
        } else if (item?.overAllStatus === "Warning") {
          tempPartiallyMeet = tempPartiallyMeet + 1;
        }
      });
      setFullyMeets(tempFullyMeet);
      setDoesNotMeets(tempDoesNotMeet);
      setPartiallyMeets(tempPartiallyMeet);
    }
    let temp = [
      {
        title: (
          <Row
            gutter={16}
            align={"middle"}
            // justify={"space-between"}
            style={{ flexFlow: "nowrap" }}
          >
            <Col className="table-heading-sm">Name</Col>
            <Col>
              <Dropdown
                menu={{ items }}
                overlayClassName="notificationFilterDropdown"
              >
                <img
                  src={FilterIcon}
                  alt="filter"
                  className="table-heading-sm"
                />
              </Dropdown>
            </Col>
          </Row>
        ),
        width: 180,
        fixed: "left",
        dataIndex: "name",
        render: (item, row) => (
          <Tooltip
            title={
              <div>
                <span>Contact Details</span>
                {row?.profileId?.phoneNumber && (
                  <Row gutter={8} align={"middle"}>
                    <Col>
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="4" cy="4" r="4" fill="#31B948" />
                      </svg>
                    </Col>

                    <Col>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.28325 6.63806C6.80525 7.72528 7.51684 8.74426 8.41803 9.64544C9.31921 10.5466 10.3382 11.2582 11.4254 11.7802C11.5189 11.8251 11.5657 11.8476 11.6249 11.8648C11.8351 11.9261 12.0933 11.8821 12.2714 11.7546C12.3215 11.7187 12.3644 11.6758 12.4501 11.5901C12.7123 11.3279 12.8434 11.1968 12.9752 11.1111C13.4724 10.7878 14.1133 10.7878 14.6105 11.1111C14.7423 11.1968 14.8734 11.3279 15.1356 11.5901L15.2818 11.7362C15.6804 12.1348 15.8797 12.3341 15.9879 12.5481C16.2032 12.9738 16.2032 13.4765 15.9879 13.9022C15.8797 14.1162 15.6804 14.3155 15.2818 14.7141L15.1636 14.8323C14.7664 15.2295 14.5677 15.4281 14.2977 15.5798C13.9981 15.7482 13.5327 15.8692 13.1891 15.8681C12.8793 15.8672 12.6677 15.8071 12.2443 15.687C9.9693 15.0413 7.82253 13.8229 6.03154 12.0319C4.24056 10.2409 3.0222 8.09417 2.37647 5.81912C2.25632 5.39579 2.19624 5.18412 2.19532 4.87441C2.1943 4.53074 2.31532 4.06538 2.48363 3.76575C2.63532 3.49572 2.83393 3.29711 3.23114 2.8999L3.34937 2.78167C3.74795 2.38309 3.94724 2.1838 4.16128 2.07554C4.58695 1.86024 5.08965 1.86024 5.51532 2.07554C5.72936 2.1838 5.92865 2.38309 6.32723 2.78167L6.47338 2.92782C6.73559 3.19003 6.86669 3.32113 6.9524 3.45296C7.27565 3.95013 7.27565 4.59107 6.9524 5.08823C6.86669 5.22007 6.73559 5.35117 6.47338 5.61337C6.38765 5.69911 6.34478 5.74198 6.3089 5.79208C6.18139 5.97016 6.13736 6.22835 6.19866 6.43862C6.2159 6.49779 6.23835 6.54454 6.28325 6.63806Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Col>
                    <Col>{"0" + row?.profileId?.phoneNumber}</Col>
                  </Row>
                )}
                {row?.profileId?.userId?.email && (
                  <Row gutter={8} align={"middle"}>
                    <Col>
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="4" cy="4" r="4" fill="#31B948" />
                      </svg>
                    </Col>
                    <Col>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.66797 5.83594L8.47207 10.5988C9.02304 10.9845 9.29853 11.1773 9.59819 11.252C9.86288 11.318 10.1397 11.318 10.4044 11.252C10.7041 11.1773 10.9796 10.9845 11.5305 10.5988L18.3346 5.83594M5.66797 16.6693H14.3346C15.7348 16.6693 16.4348 16.6693 16.9696 16.3968C17.44 16.1571 17.8225 15.7747 18.0621 15.3042C18.3346 14.7695 18.3346 14.0694 18.3346 12.6693V7.33594C18.3346 5.93581 18.3346 5.23574 18.0621 4.70096C17.8225 4.23056 17.44 3.8481 16.9696 3.60842C16.4348 3.33594 15.7348 3.33594 14.3346 3.33594H5.66797C4.26784 3.33594 3.56777 3.33594 3.03299 3.60842C2.56259 3.8481 2.18014 4.23056 1.94045 4.70096C1.66797 5.23574 1.66797 5.93581 1.66797 7.33594V12.6693C1.66797 14.0694 1.66797 14.7695 1.94045 15.3042C2.18014 15.7747 2.56259 16.1571 3.03299 16.3968C3.56777 16.6693 4.26784 16.6693 5.66797 16.6693Z"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Col>
                    <Col>{row?.profileId?.userId?.email}</Col>
                  </Row>
                )}
              </div>
            }
          >
            <span
              className="cursor-pointer"
              onClick={() =>
                navigate(`/applicant-profile/${row?.profileId?.userId?._id}`, {
                  state: {
                    id: advertId,
                  },
                })
              }
            >
              {row?.isApproved === "accepted" ? (
                <span className="approvedStyle">
                  <span>{row?.profileId?.name}</span>
                </span>
              ) : row?.isApproved === "rejected" ? (
                <span>
                  <strike className="rejectStyleClass">
                    {row?.profileId?.name}
                  </strike>
                </span>
              ) : (
                <span>{row?.profileId?.name}</span>
              )}
            </span>
          </Tooltip>
        ),
      },
    ];
    if (user === "Root" || user === "Admin" || user === "Account") {
      temp = [
        ...temp,
        {
          title: (
            <Row align={"middle"} gutter={8}>
              <Col className="table-heading-sm">
                Notes/Comments - Internal User
              </Col>
            </Row>
          ),
          dataIndex: "notes_comments",
          width: "40%",
          render: (item, row) => (
            <>
              <Row className="leave_a_comment_row" gutter={10}>
                <Col span={22}>
                  <span>
                    {row?.notes?.internalUser &&
                    row?.notes?.internalUser?.length > 50 ? (
                      <span className="all_item_content all_item_content-sm">
                        {row?.notes?.internalUser?.substring(0, 120)} {" ..."}
                        <span
                          className="view_more_link cursor_pointer"
                          onClick={() => handleEditMessage(row, "internalUser")}
                        >
                          View More
                        </span>
                      </span>
                    ) : (
                      <>{row?.notes?.internalUser}</>
                    )}
                    {!row?.notes?.internalUser && (
                      <span className="leave_comment">Leave a comment</span>
                    )}
                  </span>
                </Col>
                {(user === "Root" ||
                  user === "Admin" ||
                  user === "Account") && (
                  <Col
                    span={1}
                    onClick={() => handleEditMessage(row, "internalUser")}
                  >
                    <img src={editIcon} alt="edit" className="cursor_pointer" />
                  </Col>
                )}
              </Row>
            </>
          ),
        },
      ];
    }
    if (
      user === "Root" ||
      user === "Admin" ||
      user === "Account" ||
      user === "Associate"
    ) {
      temp = [
        ...temp,
        {
          title: (
            <Row align={"middle"} gutter={8}>
              <Col className="table-heading-sm">
                Notes/Comments - Association User
              </Col>
            </Row>
          ),
          dataIndex: "notes_comments_associate",
          width: "40%",
          render: (item, row) => (
            <>
              <Row className="leave_a_comment_row">
                <Col span={user === "Associate" ? 22 : 24}>
                  <span>
                    {row?.notes?.associateUser && (
                      <span className="all_item_content all_item_content-sm">
                        {row?.notes?.associateUser &&
                        row?.notes?.associateUser?.length > 50 ? (
                          <>
                            {row?.notes?.associateUser?.substring(0, 120)}
                            {" ..."}
                            <span
                              className="view_more_link cursor_pointer"
                              onClick={() =>
                                user === "Associate"
                                  ? handleEditMessage(row, "associateUser")
                                  : handleViewMore(row, "associateUser")
                              }
                            >
                              View More
                            </span>
                          </>
                        ) : (
                          row?.notes?.associateUser
                        )}
                      </span>
                    )}
                    {!row?.notes?.associateUser && user === "Associate" && (
                      <span className="leave_comment">Leave a comment</span>
                    )}
                    {!row?.notes?.associateUser && user !== "Associate" && (
                      <span className="leave_comment">No comment</span>
                    )}
                  </span>
                </Col>
                {user === "Associate" && (
                  <Col
                    span={1}
                    onClick={() => handleEditMessage(row, "associateUser")}
                  >
                    <img src={editIcon} alt="edit" className="cursor_pointer" />
                  </Col>
                )}
              </Row>
            </>
          ),
        },
      ];
    }
    temp = [
      ...temp,
      {
        title: "",
        dataIndex: "action",
        align: "center",
        width: 100,
        render: (item, rawData) => (
          <Row align={"middle"}>
            <Dropdown
              placement="left"
              overlayClassName="action-dropdown"
              menu={{
                items: tableActionItems(rawData),
              }}
              trigger={"click"}
            >
              <img
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteFunctionality(rawData);
                }}
                className="cursor_pointer"
                src={infoIcon}
                alt="edit"
              />
            </Dropdown>
          </Row>
        ),
      },
    ];
    temp = [...temp, {}];
    setColumns(temp);
  }, [user]);
  useEffect(() => {
    setData(data2?.users);
  }, [data2]);
  const handleUpgradeModal = async () => {
    if (JSON.parse(localStorage.getItem("isPlusSubscription"))) {
      try {
        setReportLoading(true);
        try {
          const response = await API_MANAGER.getTrackerReport(advertId);
          downloadPDF(response?.data?.data, "Candidate Report");
          setReportLoading(false);
        } catch (error) {
          setReportLoading(false);
          message.error(
            error?.response?.data?.message ||
              "Something went wrong. Please try again."
          );
        }
      } catch (error) {}
    } else {
      setUpgradeMembership(true);
    }
  };
  const verticleDotItems = [
    {
      key: "2",
      label: (
        <Row className="filter_item_row">
          <Col
            span={24}
            style={{
              color: selectedRowKeys?.length > 0 ? "#1638b1" : "#A7AEBB",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              if (selectedRowKeys?.length > 0) {
                if (user === "Associate") {
                  handleBulkAcceptRejectCandidate();
                } else {
                  handleStatus();
                }
              }
            }}
          >
            <TableStatusImage
              color={selectedRowKeys?.length > 0 ? "#1638b1" : "#A7AEBB"}
            />
            &nbsp; {user === "Associate" ? "Accept" : "Status"}
          </Col>
        </Row>
      ),
    },
    {
      key: "3",
      label: (
        <Row className="filter_item_row">
          <Col
            span={24}
            onClick={handleUpgradeModal}
            style={{
              color: JSON.parse(localStorage.getItem("isPlusSubscription"))
                ? "#1638B1"
                : "#A7AEBB",
              display: "flex",
              alignItems: "center",
            }}
          >
            <DownloadIcon
              color={
                JSON.parse(localStorage.getItem("isPlusSubscription"))
                  ? "#1638B1"
                  : "#a7aebb"
              }
            />{" "}
            &nbsp; {reportLoading ? "Downloading..." : "Download"}
          </Col>
        </Row>
      ),
    },
  ];
  const categoryItems = [
    {
      key: "1",
      label: (
        <Row>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="22"
              viewBox="0 0 4 22"
              fill="none"
            >
              <rect width="4" height="22" rx="2" fill="#8BD053" />
            </svg>
          </span>
          &nbsp; Meets must-have
        </Row>
      ),
    },
    {
      key: "2",
      label: (
        <Row>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="22"
              viewBox="0 0 4 22"
              fill="none"
            >
              <rect width="4" height="22" rx="2" fill="#F8C239" />
            </svg>
          </span>
          &nbsp; Partially meets must-have
        </Row>
      ),
    },
    {
      key: "3",
      label: (
        <Row align={"middle"}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="22"
              viewBox="0 0 4 22"
              fill="none"
            >
              <rect width="4" height="22" rx="2" fill="#F95C46" />
            </svg>
          </span>
          &nbsp; Doesn’t meet must-have
        </Row>
      ),
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setRowKey(newSelectedRowKeys);
    const temp = data2?.users?.filter((e) => {
      if (newSelectedRowKeys.includes(e?.appliedJob)) {
        return e;
      } else {
        return null;
      }
    });
    setSelectedRowData(temp);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleSearch = (e) => {
    console.log(e, "Search");
    if (e) {
      let tempSearchData = data2?.users?.filter(
        (value) =>
          value?.profileId?.name.toUpperCase()?.indexOf(e.toUpperCase()) > -1
      );
      setData(tempSearchData);
    } else {
      setData(data2?.users);
    }
  };

  const handleSelect = (filters) => {
    if (filters?.length > 0) {
      let tempFilterData = [];
      filters?.map((e) => {
        let tempFilter = data2?.users?.filter?.(
          (value) => value?.overAllStatus === e
        );
        tempFilterData = [...tempFilterData, ...tempFilter];
      });
      setData(tempFilterData);
    } else {
      setData(data2?.users);
    }
  };
  return (
    <div>
      <UpgradeMembershipModal
        setUpgradeMembership={setUpgradeMembership}
        upgradeMembership={upgradeMembership}
      />
      <EditAddMessageModal
        messageModal={messageModal}
        setMessageModal={setMessageModal}
        buttonVisible={buttonVisible}
        selectedMessage={selectedMessage}
        getData={getData}
      />
      <div className="hard_requirement_container">
        <div className="progressBox_container mt-24">
          <Row gutter={4}>
            <Col xs={0} md={8}>
              <span className="progress-text">{`Fully meets`}</span>
              {/* <span className="progress-text-bold">{`(${fullyMeets})`}</span> */}
              <div className="progressBox success_box"></div>
            </Col>
            <Col xs={0} md={8}>
              <span className="progress-text">{`Partially meets`}</span>
              {/* <span className="progress-text-bold">{`(${partiallyMeets})`}</span> */}
              <div className="progressBox partial_box"></div>
            </Col>
            <Col xs={0} md={8}>
              <span className="progress-text">{`Doesn’t meet`}</span>
              {/* <span className="progress-text-bold">{`(${doesNotMeets})`}</span> */}
              <div className=" progressBox red_box"></div>
            </Col>
            {/* ************ for sm only ************* */}
            <Col xs={8} md={0}>
              <span className="progress-text">{`Fully meets`}</span>
              {/* <span className="progress-text-bold">{`(3)`}</span> */}
              <div className="progressBox success_box"></div>
            </Col>
            <Col xs={8} md={0}>
              <span className="progress-text">{`Partially meets`}</span>
              {/* <span className="progress-text-bold">{`(3)`}</span> */}
              <div className="progressBox partial_box"></div>
            </Col>
            <Col xs={8} md={0}>
              <span className="progress-text">{`Doesn’t meet`}</span>
              {/* <span className="progress-text-bold">{`(4)`}</span> */}
              <div className=" progressBox red_box"></div>
            </Col>
          </Row>
        </div>
        <div className="mt-24 tableContainer">
          <div className="advert_table_box">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={paginatedData}
              className="soft_requirement_table"
              pagination={false}
              scroll={{ y: 400, x: "calc(750px + 40%)" }}
              rowKey={"appliedJob"}
              title={() => (
                <Row justify={"space-between"} className="align_items_center">
                  <Col xs={0} md={10}>
                    {user !== "Associate" && (
                      <Row gutter={{ md: 16, lg: 32 }} justify={"start"}>
                        <Col>
                          <Row
                            className="cursor-pointer"
                            gutter={6}
                            align={"middle"}
                            onClick={() => {
                              if (selectedRowKeys?.length > 0) {
                                handleDelete(selectedRowKeys);
                              }
                            }}
                          >
                            <Col>
                              <CommonDeleteSvg
                                color={
                                  selectedRowKeys?.length > 0
                                    ? "#d03c28"
                                    : "#A7AEBB"
                                }
                              />{" "}
                            </Col>
                            <Col
                              className="delete"
                              style={{
                                color:
                                  selectedRowKeys?.length > 0
                                    ? "#d03c28"
                                    : "#A7AEBB",
                              }}
                            >
                              Delete
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row
                            gutter={6}
                            align={"middle"}
                            className="cursor-pointer"
                            onClick={() => {
                              if (selectedRowKeys?.length > 0) {
                                if (user === "Associate") {
                                  handleBulkAcceptRejectCandidate();
                                } else {
                                  handleStatus();
                                }
                              }
                            }}
                          >
                            <Col style={{ marginTop: "4px" }}>
                              <TableStatusImage
                                color={
                                  selectedRowKeys?.length > 0
                                    ? "#1638b1"
                                    : "#A7AEBB"
                                }
                              />
                            </Col>
                            <Col
                              className={
                                selectedRowKeys?.length > 0
                                  ? "delete activeLink"
                                  : "delete deactivatedLink"
                              }
                            >
                              {user === "Associate" ? "Accept" : "Status"}
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col
                              xs={24}
                              onClick={handleUpgradeModal}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                                cursor: "pointer",
                              }}
                            >
                              <DownloadIcon
                                color={
                                  JSON.parse(
                                    localStorage.getItem("isPlusSubscription")
                                  )
                                    ? "#1638B1"
                                    : "#a7aebb"
                                }
                              />{" "}
                              {/* <img src={downloadIcon} /> &nbsp; */}
                              <span
                                className={
                                  JSON.parse(
                                    localStorage.getItem("isPlusSubscription")
                                  )
                                    ? "activeLink"
                                    : "deactiveLink"
                                }
                              >
                                {reportLoading
                                  ? "Downloading..."
                                  : "Candidate Report"}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                    {user === "Associate" && (
                      <Row gutter={{ md: 16, lg: 32 }} justify={"start"}>
                        <Col>
                          <Row
                            gutter={6}
                            align={"middle"}
                            className="cursor-pointer"
                            onClick={() => {
                              if (selectedRowKeys?.length > 0) {
                                handleBulkAcceptRejectCandidate();
                              }
                            }}
                          >
                            <Col style={{ marginTop: "4px" }}>
                              <TableStatusImage
                                color={
                                  selectedRowKeys?.length > 0
                                    ? "#1638B1"
                                    : "#A7AEBB"
                                }
                              />
                            </Col>
                            <Col
                              className="delete"
                              style={{
                                color:
                                  selectedRowKeys?.length > 0
                                    ? "#1638b1"
                                    : "#A7AEBB",
                              }}
                            >
                              Accept
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col
                              xs={24}
                              onClick={handleUpgradeModal}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                                cursor: "pointer",
                              }}
                            >
                              <DownloadIcon
                                color={
                                  JSON.parse(
                                    localStorage.getItem("isPlusSubscription")
                                  )
                                    ? "#1638B1"
                                    : "#a7aebb"
                                }
                              />{" "}
                              {/* <img src={downloadIcon} /> &nbsp; */}
                              <span
                                className={
                                  JSON.parse(
                                    localStorage.getItem("isPlusSubscription")
                                  )
                                    ? "activeLink"
                                    : "deactiveLink"
                                }
                              >
                                {reportLoading
                                  ? "Downloading..."
                                  : "Candidate Report"}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col xs={0} md={14}>
                    <Row gutter={16} align={"middle"}>
                      <Col span={14}>
                        <Input.Search
                          className="searchBox w-100"
                          placeholder="Search candidate name"
                          value={search}
                          onChange={(e) => {
                            setSearch(e?.target?.value);
                            handleSearch(e?.target?.value);
                            setCurrentPage(1);
                          }}
                          suffix={
                            search && (
                              <img
                                src={CancelIcon}
                                className="cursor_pointer"
                                alt="cross"
                                onClick={() => {
                                  setSearch(null);
                                  handleSearch(null);
                                  setCurrentPage(1);
                                }}
                              />
                            )
                          }
                          prefix={<img src={SearchIcon} alt="search" />}
                        />
                      </Col>
                      <Col span={10}>
                        <Select
                          placeholder="Select category "
                          optionLabelProp="label"
                          className="w-100 selectBox"
                          onChange={(e) => {
                            setFilterType(e);
                            handleSelect(e);
                          }}
                          maxTagCount={1}
                          mode="multiple"
                        >
                          <Select.Option
                            className="multiselect_items_col"
                            value="Yes"
                            label=" Meets must-have"
                          >
                            <Row gutter={8}>
                              <Col>
                                <div className="verticalBox"></div>
                              </Col>
                              <Col>
                                <Checkbox
                                  checked={
                                    filterType.includes("Yes") ? true : false
                                  }
                                />
                              </Col>
                              <Col>Fully meets</Col>
                            </Row>
                          </Select.Option>
                          <Select.Option
                            className="multiselect_items_col"
                            value="Warning"
                            label="Partially meets must-have"
                          >
                            <Row gutter={8}>
                              <Col>
                                <div className="verticalBox yellowVerticalBox "></div>
                              </Col>
                              <Col>
                                <Checkbox
                                  checked={
                                    filterType.includes("Warning")
                                      ? true
                                      : false
                                  }
                                />
                              </Col>
                              <Col> Partially meets</Col>
                            </Row>
                          </Select.Option>
                          <Select.Option
                            className="multiselect_items_col"
                            label="Doesn’t meet must-have"
                            value="No"
                          >
                            <Row gutter={8}>
                              <Col>
                                <div className="verticalBox redVerticalBox"></div>
                              </Col>
                              <Col>
                                <Checkbox
                                  checked={
                                    filterType.includes("No") ? true : false
                                  }
                                />
                              </Col>
                              <Col>Doesn’t meet</Col>
                            </Row>
                          </Select.Option>
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  {/* for small screen only */}
                  <Col md={0} xs={21}>
                    <Input.Search
                      className="searchBox w-100"
                      placeholder="Search candidate name"
                      prefix={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M17 17L13.1396 13.1396M13.1396 13.1396C13.7999 12.4793 14.3237 11.6953 14.6811 10.8326C15.0385 9.96978 15.2224 9.04507 15.2224 8.11121C15.2224 7.17735 15.0385 6.25264 14.6811 5.38987C14.3237 4.5271 13.7999 3.74316 13.1396 3.08283C12.4793 2.42249 11.6953 1.89868 10.8326 1.54131C9.96978 1.18394 9.04507 1 8.11121 1C7.17735 1 6.25264 1.18394 5.38987 1.54131C4.5271 1.89868 3.74316 2.42249 3.08283 3.08283C1.74921 4.41644 1 6.2252 1 8.11121C1 9.99722 1.74921 11.806 3.08283 13.1396C4.41644 14.4732 6.2252 15.2224 8.11121 15.2224C9.99722 15.2224 11.806 14.4732 13.1396 13.1396Z"
                            stroke="#1638B1"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                      // ****************testing***************//
                      suffix={
                        <Dropdown
                          menu={{
                            items: categoryItems,
                          }}
                          placement="bottomLeft"
                          trigger={["click"]}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M3 8L15 8M15 8C15 9.65686 16.3431 11 18 11C19.6569 11 21 9.65685 21 8C21 6.34315 19.6569 5 18 5C16.3431 5 15 6.34315 15 8ZM9 16L21 16M9 16C9 17.6569 7.65685 19 6 19C4.34315 19 3 17.6569 3 16C3 14.3431 4.34315 13 6 13C7.65685 13 9 14.3431 9 16Z"
                              stroke="#CDD2DC"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Dropdown>
                      }
                    />
                  </Col>
                  <Col md={0} xs={2}>
                    {/* dropdown for verticle dot */}
                    <Dropdown
                      menu={{
                        items:
                          user === "Associate"
                            ? verticleDotItems
                            : [
                                ...verticleDotItems,
                                {
                                  key: "1",
                                  label: (
                                    <Row
                                      className="filter_item_row"
                                      onClick={() => {
                                        if (selectedRowKeys?.length > 0) {
                                          handleDelete(selectedRowKeys);
                                        }
                                      }}
                                    >
                                      <Col
                                        span={24}
                                        style={{
                                          color:
                                            selectedRowKeys?.length > 0
                                              ? "#d03c28"
                                              : "#A7AEBB",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <CommonDeleteSvg
                                          color={
                                            selectedRowKeys?.length > 0
                                              ? "#d03c28"
                                              : "#A7AEBB"
                                          }
                                        />{" "}
                                        &nbsp; Delete
                                      </Col>
                                    </Row>
                                  ),
                                },
                              ],
                      }}
                      overlayClassName="notificationFilterDropdown"
                      placement="bottomLeft"
                      trigger={["click"]}
                    >
                      <img
                        src={verticleDot}
                        alt="vertical dots"
                        className="vertical-dot"
                      />
                    </Dropdown>
                  </Col>
                </Row>
              )}
              rowClassName={(row) =>
                row?.overAllStatus === "Yes"
                  ? "successRow"
                  : row?.overAllStatus === "Warning"
                  ? "warningRow"
                  : "closeRow"
              }
            />
            <CustomPagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              total={data2?.totalCount}
              itemPerPage={dataperpage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notes;

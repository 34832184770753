import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import HardRequirementsDropArea from "./HardRequirementsDropArea";
import RequirementItem from "./RequirementItem";
import { Button, Input, Row } from "antd";
import {
  InputSearchCrossIcon,
  InputSearchSuffix,
} from "../../../Assets/campagin/sectionProgress/svgs";
import RequirementItemSearchable from "./RequirementItemSearchable";
import { DragablleIcon } from "../../../Assets/campagin/sectionProgress/svgs";
const REQUIREMENTS = [
  {
    id: "first",
    content: "Qualification",
    label: "Qualification",
    unique_id: "QUALIFICATION",
    tags: "QUALIFICATION",
    type: "select",
  },
  {
    id: "second",
    content: "Industry",
    label: "Industry",
    unique_id: "INDUSTRY",
    tags: "INDUSTRY",
  },
  {
    id: "third",
    content: "Inductions",
    label: "Induction",
    unique_id: "INDUCTION",
    tags: "INDUCTION",
  },
  {
    id: "fourth",
    content: "Skills",
    label: "Skill",
    unique_id: "SKILL",
    tags: "SKILL",
  },
  {
    id: "fifth",
    content: "Citizenship",
    label: "Citizenship",
    unique_id: "CITIZENSHIP",
    tags: "CITIZENSHIP",
    type: "single",
  },
  {
    id: "sixth",
    content: "Visa Type Number",
    label: "Visa Type Number",
    unique_id: "VISA",
    tags: "VISA",
    type: "one",
  },
  {
    id: "seventh",
    content: "Police Clearance",
    label: "Police Clearance",
    unique_id: "POLICE_CLEARANCE",
    tags: "CERTIFYING_AUTHORITY",
    type: "single",
  },
  {
    id: "eighth",
    content: "Medical Assessment",
    label: "Medical Assessment",
    unique_id: "MEDICAL_ASSESSMENT",
    tags: "MEDICAL_ASSESSMENT",
  },
  {
    id: "ninth",
    content: "ID Document",
    label: "ID Document",
    unique_id: "ID_TYPE",
    tags: "ID_TYPE",
    type: "single",
  },
];

function AddRequirementForm({
  handleSubmit,
  setprogress,
  defaultData,
  setCurrentForm,
  form,
  isHidden,
  softReqSelected,
  hardReqSelected,
  setHardReqSelected,
  setSoftReqSelected,
  isEdit,
  columns,
  setColumns,
  setSoftReqColumn,
  softReqColumn,
}) {
  const [progressFlag, setprogressFlag] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleClearClick = () => {
    setSearchValue("");
  };

  const handelProgress = () => {
    if (columns?.hard_req?.items?.length == 0 && progressFlag == true) {
      setprogressFlag(false);
      setprogress(50);
    } else if (columns?.hard_req?.items?.length == 0) return;
    else if (columns?.hard_req?.items?.length > 0 && progressFlag == false) {
      setprogress(75);
      setprogressFlag(true);
    } else if (columns?.hard_req?.items?.length > 0 && progressFlag == true)
      return;
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      let tempIndex = sourceItems?.findIndex(
        (e) => e?.id === result?.draggableId
      );
      if (source.droppableId === "hard_req") {
        sourceItems[tempIndex].selected = [];
      }
      const [removed] = sourceItems.splice(tempIndex, 1);
      destItems.splice(destination.index, 0, removed);
      const temp = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
      const tempColumn = temp?.hard_req?.items?.map((item) => item?.unique_id);
      let allColumn = REQUIREMENTS?.filter((item) => {
        if (
          (item?.type === "single" || item?.type === "one") &&
          tempColumn?.includes(item?.unique_id)
        ) {
          return false;
        } else {
          return true;
        }
      });
      let softColumn = softReqColumn?.soft_req?.items?.map(
        (item) => item?.unique_id
      );
      allColumn = allColumn?.filter(
        (item) => !softColumn?.includes(item?.unique_id)
      );
      const columnsFromBackend = {
        ...softReqColumn,
        ["all_requ"]: {
          name: "All Task ",
          items: allColumn,
          type: "all",
        },
      };
      setSoftReqColumn(columnsFromBackend);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);

      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };
  const handelSelect = (data, type, title, mode) => {
    if (type === "hard_req") {
      setHardReqSelected((p) => ({
        ...p,
        [title?.toUpperCase()]:
          mode === "single" ? [data] : mode === "multiple" ? data : [],
      }));
    }
  };

  useEffect(() => {
    let AllReq = [...REQUIREMENTS];
    const tempColumn = defaultData?.softRequirements
      ? Object.keys(defaultData?.softRequirements)
      : [];
    AllReq = REQUIREMENTS?.filter((item) => {
      if (
        (item?.type === "single" || item?.type === "one") &&
        tempColumn?.includes(item?.unique_id)
      ) {
        return false;
      } else {
        return true;
      }
    });
    const hardReqs = defaultData?.hardRequirements;
    const handleHardReq = () => {
      let defaultHardReqData = [];
      if (hardReqs) {
        let hardReqKeys = Object.keys(hardReqs);
        defaultHardReqData = AllReq.filter((a) =>
          hardReqKeys?.includes(a?.tags)
        ).map((req) => ({ ...req, selected: hardReqs[req?.tags] }));
        AllReq = AllReq.filter(
          (a) => !defaultHardReqData?.find((req) => req.tags === a?.tags)
        );
      }

      return defaultHardReqData;
    };

    const columnsFromBackend = {
      hard_req: {
        name: "hard Requ",
        items: handleHardReq(),
        type: "hard",
      },
      all_requ: {
        name: "All Task ",
        items: AllReq,
        type: "all",
      },
    };
    setColumns(columnsFromBackend);
  }, [defaultData]);

  useEffect(() => {
    handelProgress();
  }, [onDragEnd]);

  useEffect(() => {
    setHardReqSelected(defaultData?.hardRequirements);
  }, [defaultData]);

  useEffect(() => {
    let hardRequirements = {};
    columns?.hard_req?.items?.forEach((item) => {
      hardRequirements[item?.tags] = hardReqSelected[item?.tags];
    });
    setHardReqSelected(hardRequirements);
  }, [columns?.hard_req]);

  let rightColums = (column) => {
    let list = column.items
      ?.filter((item) => item?.content?.toLowerCase()?.includes(searchValue))
      .map((item, index) => {
        return (
          <Draggable
            key={item.id}
            draggableId={item.id}
            index={index}
            id={item?.id}
          >
            {(provided, snapshot) => {
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    margin: "0px 0px 20px 0px",
                    userSelect: "none",
                    borderRadius: "8px",
                    backgroundColor: "#FFF",
                    ...provided.draggableProps.style,
                  }}
                >
                  <RequirementItem
                    dragging={snapshot.isDragging}
                    title={item?.content}
                  />
                </div>
              );
            }}
          </Draggable>
        );
      });

    return list?.length ? (
      list
    ) : (
      <div className="single-item-title" style={{ textAlign: "center" }}>
        No Data
      </div>
    );
  };

  return (
    <div style={{ display: isHidden ? "none" : "block" }}>
      <div
        style={{ display: "flex", width: "100%" }}
        className="add-requirement-main-container"
      >
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {/* hard req container */}
          <div className=" w-100">
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <>
                  {column.type === "hard" && (
                    <div key={columnId} className="h-100 w-100">
                      <div
                        className="h-100"
                        style={{
                          marginRight: 8,
                        }}
                      >
                        <Droppable droppableId={columnId} key={columnId}>
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  userSelect: "none",
                                  borderRadius: "8px",
                                  backgroundColor: snapshot.isDragging
                                    ? "#BCC3D0"
                                    : "#FFF",
                                  color: "white",
                                  // minHeight: 265,
                                  height: "calc(100vh - 380px)",
                                  overflow: "auto",
                                  // height: "100%",
                                }}
                                className="soft-hard-box"
                              >
                                {/* Check if there are any items in the column */}
                                {column?.items?.length === 0 ? (
                                  <div
                                    style={{
                                      margin: "0px 0px 20px 0px",
                                      userSelect: "none",
                                      borderRadius: "8px",
                                      backgroundColor: "#FFF",
                                      color: "black",
                                    }}
                                  >
                                    <div
                                      style={{
                                        // maxWidth: snapshot.isDraggingOver
                                        //   ? "unset"
                                        //   : "395px",
                                        display: "block",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                      }}
                                    >
                                      <HardRequirementsDropArea
                                        title={"Hard Requirements"}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="selected_requirement_main_div ">
                                    <div className="selected_requirement_inner_div h-100">
                                      <div className="title-heading">
                                        Hard Requirement (
                                        {column?.items?.length})
                                      </div>
                                      <div className="selected_req">
                                        {column?.items.map((item, index) => {
                                          return (
                                            <Draggable
                                              key={item.id}
                                              draggableId={item.id}
                                              index={index}
                                            >
                                              {(provided, snapshot) => {
                                                return (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                      margin:
                                                        "0px 20px 20px 0px",
                                                      userSelect: "none",
                                                      borderRadius: "8px",
                                                      backgroundColor: "#FFF",
                                                      color: "white",
                                                      ...provided.draggableProps
                                                        .style,
                                                    }}
                                                  >
                                                    {item?.type === "single" ? (
                                                      <>
                                                        {/* <div className="single-item-title">
                                                        Choose {item?.label}
                                                      </div> */}
                                                        <div className="searchable-requirement-item">
                                                          <div className="draggable-icon">
                                                            <DragablleIcon />
                                                          </div>

                                                          <div className="withoutSelectBox">
                                                            <p>{item?.label}</p>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div className="single-item-title">
                                                          Choose {item?.label}
                                                        </div>

                                                        <RequirementItemSearchable
                                                          dragging={
                                                            snapshot.isDragging
                                                          }
                                                          title={item?.content}
                                                          handelSelect={
                                                            handelSelect
                                                          }
                                                          type="hard_req"
                                                          setprogress={
                                                            setprogress
                                                          }
                                                          selected={
                                                            item?.selected ?? []
                                                          }
                                                          reqDisabled={
                                                            softReqSelected
                                                          }
                                                          tags={item?.tags}
                                                          unique_id={
                                                            item?.unique_id
                                                          }
                                                        />
                                                      </>
                                                    )}
                                                  </div>
                                                );
                                              }}
                                            </Draggable>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>

          {/* Third column */}
          <div className="w-100">
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <div key={columnId}>
                  {column.type === "all" && (
                    <div className="box">
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                // width: "35vw",
                                // minHeight: 400,
                                height: "calc(100vh - 380px)",
                                overflow: "auto",
                                // height: "100%",
                              }}
                            >
                              <div className="search_requirement_div">
                                <Input
                                  className="search-input"
                                  placeholder="Search requirements"
                                  prefix={<InputSearchSuffix />} // Replace with your custom prefix icon component
                                  suffix={
                                    searchValue && (
                                      <div
                                        className="align-center d-flex"
                                        onClick={handleClearClick}
                                      >
                                        <InputSearchCrossIcon />
                                      </div>
                                    )
                                  }
                                  value={searchValue}
                                  onChange={(e) =>
                                    setSearchValue(e.target.value)
                                  }
                                />
                              </div>
                              <div style={{ padding: "24px" }}>
                                {rightColums(column)}
                              </div>
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </DragDropContext>
      </div>
      <div
        className="add-requirement-main-container justify-end d-flex w-100 "
        style={{ width: "100%" }}
      >
        <Row className="post-advert-btn">
          <Button
            className="secondaryBtn cancelBtn"
            onClick={() => {
              setCurrentForm("advert");
            }}
          >
            Cancel
          </Button>
          <Button
            className="primaryBtn"
            onClick={() => handleSubmit(false, false)}
          >
            Soft Requirement
          </Button>
          <Button
            className="primaryBtn"
            onClick={() => {
              handleSubmit(true, false);
            }}
          >
            {isEdit ? "Update" : "+ Post Advert"}
          </Button>
        </Row>
      </div>
    </div>
  );
}

export default AddRequirementForm;

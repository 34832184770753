import { Button, Col, Modal, Row, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import API_MANAGER from "../../API";
import AlertState from "../../components/common/AlertState";

function PermissionModal({
  permissionModalOpen,
  setPermissionModalOpen,
  singleUserData,
  permissionIndex,
  editUser,
  permissions,
  id,
  editProfile,
}) {
  // const [permissions, setPermissions] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertState, setAlertState] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const params = useParams();

  const navigate = useNavigate();
  const handleOk = async () => {
    let userType = 0;
    if (permissionIndex === 0) userType = "Admin";
    else if (permissionIndex === 1) userType = "Standard";
    else if (permissionIndex === 2) userType = "Associate";
    const data = {
      recruiterUserType: userType,
      permissions: permissions,
    };
    const paramId = params?.id ? params?.id : id;
    try {
      await API_MANAGER.updateUserPermission(data, paramId);
      if (paramId === localStorage.getItem("userID")) {
        if (userType === "Standard") {
          localStorage.setItem("user", "Account");
          localStorage.setItem("permissions", JSON.stringify(permissions));
          if (permissions.toString()?.includes(3)) {
            navigate("/user-management", {
              state: { permissionsUpdated: true },
            });
          } else if (userType === "Admin") {
            navigate("/campaign-management", {
              state: { permissionsUpdated: true },
            });
          } else {
            navigate("/campaign-management", {
              state: { permissionsUpdated: true },
            });
          }
        } else if (userType === "Associate") {
          localStorage.setItem("user", "Associate");
          navigate("/job-advert", { state: { permissionsUpdated: true } });
        } else {
          localStorage.setItem("user", "Admin");
          navigate("/user-management", { state: { permissionsUpdated: true } });
        }
      }
      message.success("Permission successfully updated.");
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message ||
          "Something went wrong. Please try again."
      );
    }
    setPermissionModalOpen(false);
    // navigate("/user-management");
  };
  const handleCancel = () => {
    setPermissionModalOpen(false);
  };
  return (
    <>
      <AlertState
        state={alertState}
        message={alertMessage}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
      />

      <Modal
        centered
        className="permissionModal"
        title="Change User?"
        open={permissionModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Row>
            <Col span={24} align={"right"} className="justify_items_end">
              <Button key="submit" className="cancel_btn" onClick={handleOk}>
                Cancel
              </Button>
              <Button
                key="submit"
                className="black_color_button"
                onClick={handleOk}
              >
                Yes
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row>
          <Col xs={24}>
            <span className="desc">
              Are you sure you want to change user permissions for{" "}
            </span>
            <span className="highlight_name">
              {singleUserData?.firstName?.charAt(0)?.toUpperCase() +
                singleUserData?.firstName?.substring(1) +
                " " +
                singleUserData?.lastName?.charAt(0)?.toUpperCase() +
                singleUserData?.lastName?.substring(1)}
              ?
            </span>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
export default PermissionModal;

import React, { useState, useEffect } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { Row, Col, Radio, Collapse, message, Modal } from "antd";
import EditIcon from "../../Assets/editIcon.svg";
import status_image from "../../Assets/advert_detail/status.svg";
import DeleteIconRed from "../../Assets/user_mangement/delete_icon_box.svg";
import SuccessIcon from "../../Assets/campagin/success.svg";
import WarningIcon from "../../Assets/campagin/warning.svg";
import StopIcon from "../../Assets/campagin/stop.svg";
import alert_triangle from "../../Assets/advert_detail/alert-triangle.svg";
import transeferImage from "../../Assets/advert_detail/transfer.svg";
import action_delete from "../../Assets/advert_detail/action-delete.svg";
import HardRequirements from "../../components/common/AdvertTracker/HardRequirements";
import SoftRequirements from "../../components/common/AdvertTracker/SoftRequirements";
import Notes from "../../components/common/AdvertTracker/Notes";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import API_MANAGER from "../../API";
import moment from "moment";
import { DeleteAdvertModal, DeleteModal } from "../../components/common/Popup";
import Reportpopup, {
  StatusModal,
  TransferModal,
  UpdatestatusPopup,
} from "../../components/common/AdvertDetail/Reportpopup";
import NotificationPopup from "../../components/common/notificationPopup";
import { numberWithCommas } from "../../utils/constant";
function TrackerHistory() {
  const { id } = useParams();
  const [type, setType] = useState(
    localStorage.getItem("editType") &&
      localStorage.getItem("editType") !== "undefined"
      ? localStorage.getItem("editType")
      : "HARD_REQUIREMENTS"
  );
  const [showFullProfile, setShowFullProfile] = useState(false);
  const [data, setTableData] = useState({});
  console.log(data, "MODAL CHECK");
  const [advertDetail, setAdvertDetail] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeDeleteModal, setActiveDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteKeys, setDeleteKeys] = useState();
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [errorNotification, setErrorNotification] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [updatePopup, setUpdatePopup] = useState(false);
  // const [bulkDeleteModal, setBulkDeleteModal] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [candidateSelectedData, setCandidateSelectedData] = useState({});
  const [reportModal, setReportModal] = useState(false);
  const [transferData, setTransferData] = useState([]);
  const [advertSearch, setAdvertSearch] = useState("");
  const [reloadState, setReloadState] = useState(false);

  const [loading, setLoading] = useState(false);
  const { Panel } = Collapse;
  const navigate = useNavigate();
  const userType = localStorage.getItem("user");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const deleteAdvert = async () => {
    let campaignId = advertDetail?.campaignId?.id;
    setDeleteLoading(true);
    try {
      await API_MANAGER.deleteAdvert(id);
      message.success("Advert deleted successfully!");
      setDeleteLoading(false);
      setIsModalOpen(false);
      navigate(`/campaign-detail/${campaignId}`);
    } catch (error) {
      setDeleteLoading(false);
      message.error("Something went wrong. Please try again.");
    }
  };
  const getData = async () => {
    setTableData({});
    const param = {
      trackerType: type,
      currentPage: currentPage,

      limit: 1000,
      page: 1,

    };
    try {
      const response = await API_MANAGER.getTrackerDetail(id, param);
      setTableData(response?.data?.data);
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  useEffect(() => {
    getData();
  }, [currentPage]);
  const getAdvertData = async () => {
    try {
      const response = await API_MANAGER.getAdvertDetail(id);
      setAdvertDetail(response?.data?.data);
    } catch (error) {}
  };
  const getTransferData = async (id) => {
    setLoading(true);
    // let params = {
    //   page: 1,
    //   limit: 100,
    //   sort: "-createdAt",
    // };

    let params = {
      profileId: candidateSelectedData?.profileId?._id,
      sort: "-createdAt",
      limit: 20000,
      page: 1,
    };
    if (advertSearch) {
      params = { ...params, ["search"]: advertSearch };
    }
    if (userType !== "Associate") {
      try {
        const response = await API_MANAGER.getSingleUserCampaignDetails(params);
        setTransferData(response?.data?.data);
      } catch (error) {
        message.error("Something went wrong. Please try again.");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (candidateSelectedData?.profileId?._id) getTransferData();
  }, [candidateSelectedData?.profileId?._id, advertSearch]);

  useEffect(() => {
    getAdvertData();
    getData();
  }, [type, reloadState]);
  const handleDelete = async (selectedRowKeys) => {
    const params = {
      applicationIds: [...selectedRowKeys],
    };
    setDeleteKeys(params);
    setActiveDeleteModal(true);
  };
  localStorage.removeItem("editType");
  const handleDeleteSubmit = async () => {
    try {
      const response = await API_MANAGER.bulkApplicationDelete(deleteKeys);
      message.success("Successfully deleted!");
      setDeleteKeys(null);
      setActiveDeleteModal(false);

      getData();
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };
  const updateBulkCandidateJobStatus = async () => {
    try {
      if (
        selectedRowData[0]?.progress[selectedRowData[0]?.progress?.length - 1]
          ?.state === 0
      ) {
        const param = {
          applicationIds: [...selectedRowKeys],
          status: "SHORTLIST_OFFERED",
        };
        await API_MANAGER.updateBulkCandidateJobStatus(param);
      } else if (
        selectedRowData[0]?.progress[selectedRowData[0]?.progress?.length - 1]
          ?.state === 3
      ) {
        const param = {
          applicationIds: [...selectedRowKeys],
          status: "ROLE_OFFERED",
        };
        await API_MANAGER.updateBulkCandidateJobStatus(param);
      } else {
        setStatusModalOpen(false);
        message.warning(
          "You can’t change the status of candidates of this stages of their application process."
        );
        return;
      }

      message.success("Status updated successfully!");
      setStatusModalOpen(false);
      getData();
    } catch (error) {
      message.error("Something went wrong. Please try again.");
      // setUpdateAlert(true);
    }
  };
  const handleStatusButton = () => {
    let tempBool = false;
    if (selectedRowData?.length <= 1) {
      setStatusModalOpen(true);
    } else if (selectedRowData?.length > 1) {
      if (selectedRowData[0]?.progress) {
        const tempState =
          selectedRowData[0]?.progress[selectedRowData[0]?.progress?.length - 1]
            ?.state;
        selectedRowData?.map((e) => {
          if (e?.progress) {
            if (e?.progress[e?.progress?.length - 1]?.state !== tempState) {
              setErrorNotification(true);
              tempBool = true;
              setTimeout(() => {
                setErrorNotification(false);
              }, 3000);
            }
          } else {
            const tempState1 = selectedRowData[0]?.isInvited;
            if (e?.isInvited !== tempState1) {
              setErrorNotification(true);
              tempBool = true;
              setTimeout(() => {
                setErrorNotification(false);
              }, 3000);
            }
          }
        });
      } else {
        const tempState = selectedRowData[0]?.isInvited;
        selectedRowData.map((e) => {
          if (e?.isInvited !== tempState) {
            setErrorNotification(true);
            tempBool = true;
            setTimeout(() => {
              setErrorNotification(false);
            }, 3000);
            return;
          }
        });
      }
      if (!tempBool) {
        setStatusModalOpen(true);
      }
    } else {
      setErrorNotification(true);
      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
    }
  };
  const handleAcceptReject = async (value, row) => {
    setLoading(true);
    try {
      await API_MANAGER.acceptRejectCandidate(
        { isApproved: value },
        row?.appliedJob
      );
      message.success("Successfully updated candidate.");
      getData();
    } catch (error) {
      message.error(
        error.response.data.message.message ||
          "Something went wrong. Please try again."
      );
    }
    setLoading(false);
  };
  let selectedRowDataFunct;
  const handleDeleteFunctionality = async (data) => {
    selectedRowDataFunct = data;
  };
  const tableActionItems = (row) => {
    return [
      {
        key: "1",
        label: (
          <div className="action-dropdown">
            {userType !== "Associate" ? (
              <>
                <Row>
                  <Col
                    onClick={() => setUpdatePopup(true)}
                    className="item"
                    span={24}
                  >
                    <img src={status_image} />
                    <span>Status</span>
                  </Col>
                </Row>
                <Row>
                  <Col
                    onClick={() => {
                      const params = {
                        applicationIds: [selectedRowDataFunct?.appliedJob],
                      };
                      setDeleteKeys(params);
                      setActiveDeleteModal(true);
                    }}
                    className="item"
                    span={24}
                  >
                    <img src={action_delete} />
                    <span>Delete</span>
                  </Col>
                </Row>
                <Row>
                  <Col
                    onClick={() => {
                      setTransferModal({ transfer: true });
                    }}
                    className="item"
                    span={24}
                  >
                    <img src={transeferImage} />
                    <span>Transfer</span>
                  </Col>
                </Row>
                <Row>
                  <Col
                    onClick={() => setReportModal(true)}
                    className="item"
                    span={24}
                  >
                    <img src={alert_triangle} />
                    <span>Report</span>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col
                    onClick={() => handleAcceptReject("accepted", row)}
                    className="item"
                    span={24}
                  >
                    <img src={status_image} />
                    <span>Accept</span>
                  </Col>
                </Row>
                <Row>
                  <Col
                    onClick={() => handleAcceptReject("rejected", row)}
                    className="item"
                    span={24}
                  >
                    <img src={action_delete} />
                    <span>Reject</span>
                  </Col>
                </Row>
              </>
            )}
          </div>
        ),
      },
    ];
  };
  const updateCandidateJobStatus = async () => {
    candidateSelectedData["_id"] = candidateSelectedData?.appliedJob;
    try {
      if (
        candidateSelectedData?.progress[
          candidateSelectedData?.progress?.length - 1
        ]?.state === 0
      ) {
        await API_MANAGER.updateCandidateJobStatus(candidateSelectedData?._id, {
          status: "SHORTLIST_OFFERED",
        });
      } else if (
        candidateSelectedData?.progress[
          candidateSelectedData?.progress?.length - 1
        ]?.state === 2
      ) {
        await API_MANAGER.acceptShortlistOffer({
          applicationId: candidateSelectedData?._id,
          acceptance: true,
        });
      } else if (
        candidateSelectedData?.progress[
          candidateSelectedData?.progress?.length - 1
        ]?.state === 3
      ) {
        await API_MANAGER.updateCandidateJobStatus(candidateSelectedData?._id, {
          status: "ROLE_OFFERED",
        });
      }

      message.success("Status successfully updated.");
      setUpdatePopup(false);
      getData();
    } catch (error) {
      // message.error("Something went wrong. Please try again.");
      // setUpdateAlert(true);
      message.error(
        "A Candidate must accept your previous request, before you can push them to the next stage of the recruitment process."
      );
      setUpdatePopup(false);
    }
  };
  console.log("RERENDER?");

  return (
    <SubLayout page={"Advert Details"} whiteBg={true} showBack showSearch>
      <DeleteModal
        visible={activeDeleteModal}
        setVisible={setActiveDeleteModal}
        text={"Applications"}
        handleSubmit={handleDeleteSubmit}
        loading={deleteLoading}
      />
      {errorNotification && <NotificationPopup />}

      <StatusModal
        open={statusModalOpen}
        setOpen={setStatusModalOpen}
        candidateSelectedData={
          selectedRowData?.length > 0 ? selectedRowData[0] : {}
        }
        onSubmit={updateBulkCandidateJobStatus}
        selectedRowData={selectedRowData}
      />
      <UpdatestatusPopup
        updatePopup={updatePopup}
        setUpdatePopUp={setUpdatePopup}
        setUpdatePopup={setUpdatePopup}
        candidateSelectedData={candidateSelectedData}
        onSubmit={updateCandidateJobStatus}
      />
      <TransferModal
        transferModal={transferModal}
        setTransferModal={setTransferModal}
        candidateSelectedData={candidateSelectedData}
        oldAdvertId={id}
        profileId={candidateSelectedData?.profileId?._id}
        candidateName={candidateSelectedData?.profileId?.name}
        setReloadState={setReloadState}
        transferData={transferData}
        search={advertSearch}
        setSearch={setAdvertSearch}
      />

      <Reportpopup
        data={{ candidateName: candidateSelectedData?.profileId?.name }}
        reportModal={reportModal}
        setReportModal={setReportModal}
      />
      <div className="advert_tracker_container">
        {/*******  advert detail header  ********/}
        <Row>
          <Col xs={24} md={0}>
            <p className="gbck" onClick={() => navigate(-1)}>
              {"< Advert Details"}
            </p>
          </Col>
        </Row>
        <div className="advert_detail_box">
          <Row align={"middle"} justify={"space-between"}>
            <Col>
              <div className="name">{advertDetail?.jobTitle?.name}</div>
            </Col>
            <Col>
              <Row align={"middle"} gutter={8} className="card-align-left">
                {userType !== "Associate" && (
                  <>
                    <Col>
                      <img
                        onClick={showModal}
                        className="cursor-pointer"
                        style={{ minHeight: "32px", minWidth: "32px" }}
                        src={DeleteIconRed}
                        alt="edit"
                      />
                    </Col>
                    <Col>
                      <img
                        className="cursor-pointer"
                        src={EditIcon}
                        alt="edit"
                        onClick={() =>
                          navigate(`/create-advert?id=${advertDetail?.id}`, {
                            state: { advertDetail: advertDetail, type: type },
                          })
                        }
                      />
                    </Col>
                  </>
                )}
                {/* down arrow icons svg */}
                <Col>
                  <svg
                    className="cursor-pointer"
                    onClick={() => setShowFullProfile((prev) => !prev)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M6 9.5L12 15.5L18 9.5"
                      stroke="#122D8E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="detail_row">
            <Row gutter={16}>
              <Col xs={12} md={6}>
                <p className="title">Location</p>
                <p className="detail">
                  {advertDetail?.suburb +
                    ", " +
                    advertDetail?.state?.name +
                    ", " +
                    advertDetail?.postalCode}
                </p>
              </Col>
              <Col xs={12} md={6}>
                <p className="title card-align-left">Salary/Rate</p>
                <p className="detail card-align-left">
                  {advertDetail?.minSalary &&
                  advertDetail?.maxSalary &&
                  advertDetail?.minSalary !== advertDetail?.maxSalary
                    ? `$${
                        advertDetail?.minSalary
                          ? numberWithCommas(
                              advertDetail?.minSalary?.toString()?.includes(".")
                                ? parseFloat(advertDetail?.minSalary)?.toFixed(
                                    2
                                  )
                                : advertDetail?.minSalary
                            )
                          : 0
                      }-$${
                        advertDetail?.maxSalary
                          ? numberWithCommas(
                              advertDetail?.maxSalary?.toString()?.includes(".")
                                ? parseFloat(advertDetail?.maxSalary)?.toFixed(
                                    2
                                  )
                                : advertDetail?.maxSalary
                            )
                          : 0
                      }`
                    : `$${
                        advertDetail?.minSalary
                          ? numberWithCommas(
                              advertDetail?.minSalary?.toString()?.includes(".")
                                ? parseFloat(advertDetail?.minSalary)?.toFixed(
                                    2
                                  )
                                : advertDetail?.minSalary
                            )
                          : 0
                      }`}
                </p>
              </Col>
              <Col xs={12} md={6}>
                <p className="title">Start Date</p>
                <p className="detail">
                  {moment(advertDetail?.startDate).format("DD MMM YYYY")}
                </p>
              </Col>
              <Col xs={12} md={6}>
                <p className="title card-align-left">End Date</p>
                <p className="detail card-align-left">
                  {moment(advertDetail?.endDate).format("DD MMM YYYY")}
                </p>
              </Col>
            </Row>
            {/* this data will show when down arrow button click */}
            {showFullProfile && (
              <>
                <Row gutter={16}>
                  <Col xs={12} md={6}>
                    <p className="title">Industry</p>
                    <p className="detail">{advertDetail?.industry?.name}</p>
                  </Col>
                  <Col xs={12} md={6}>
                    <p className="title card-align-left">Hours Per Week</p>
                    <p className="detail card-align-left">
                      {advertDetail?.hours} hrs
                    </p>
                  </Col>
                  <Col xs={12} md={6}>
                    <p className="title">Roster</p>
                    <p className="detail">{advertDetail?.roster?.name}</p>
                  </Col>
                  <Col xs={12} md={6}>
                    <p className="title card-align-left">Job Duration</p>
                    <p className="detail card-align-left">
                      {advertDetail?.jobDuration?.name}
                    </p>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={12} md={6}>
                    <p className="title">Discipline</p>
                    <p className="detail">{advertDetail?.discipline?.name}</p>
                  </Col>
                  <Col xs={12} md={6}>
                    <p className="title card-align-left">Employment Type</p>
                    <p className="detail card-align-left">
                      {advertDetail?.employmentType?.name}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <p className="title">Job Description</p>
                    <p className="detail">{advertDetail?.jobDescription}</p>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
        {/* ******************************************* */}
        <div className="tabsContainer">
          <Row align={"middle"} justify={"space-between"}>
            <Col xs={24} md={13}>
              <div className="radioButtons">
                <Radio.Group
                  className="tabsContainer-sm"
                  defaultValue={type ? type : "HARD_REQUIREMENTS"}
                  onChange={(e) => setType(e?.target?.value)}
                >
                  <Radio.Button
                    value="HARD_REQUIREMENTS"
                    className="tabs-child"
                  >
                    Hard Requirements
                  </Radio.Button>
                  <Radio.Button
                    value="SOFT_REQUIREMENTS"
                    className="tabs-child"
                  >
                    Soft Requirements
                  </Radio.Button>
                  <Radio.Button value="NOTES" className="tabs-child">
                    Notes
                  </Radio.Button>
                </Radio.Group>
              </div>
            </Col>
            {/* yes warning no section */}
            <Col xs={24} md={10} className="all-sign">
              <Row gutter={24} className="all-sign-child">
                <Col span={7}>
                  <Row
                    align={"middle"}
                    gutter={8}
                    justify={{ md: "end", xs: "start" }}
                  >
                    <Col>
                      <img src={SuccessIcon} alt="img" />
                    </Col>
                    <Col className="advert-bold-text">Yes</Col>
                  </Row>
                </Col>
                <Col span={9}>
                  <Row
                    align={"middle"}
                    gutter={8}
                    justify={{ md: "end", xs: "start" }}
                  >
                    <Col>
                      <img src={WarningIcon} alt="img" />
                    </Col>
                    <Col className="advert-bold-text"> Warning</Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row
                    align={"middle"}
                    gutter={8}
                    justify={{ md: "end", xs: "start" }}
                    style={{ marginRight: "2%" }}
                  >
                    <Col>
                      <img src={StopIcon} alt="img" />
                    </Col>
                    <Col className="advert-bold-text">No</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {type === "HARD_REQUIREMENTS" && (
          <div>
            <HardRequirements
              data2={data}
              handleDelete={handleDelete}
              advertId={id}
              setSelectedRowData={setSelectedRowData}
              handleStatus={handleStatusButton}
              setRowKey={setSelectedRowKeys}
              tableActionItems={tableActionItems}
              setCandidateSelectedData={setCandidateSelectedData}
              getData={getData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              handleDeleteFunctionality={handleDeleteFunctionality}
            />
          </div>
        )}
        {type === "SOFT_REQUIREMENTS" && (
          <div>
            <SoftRequirements
              data2={data}
              handleDelete={handleDelete}
              advertId={id}
              setSelectedRowData={setSelectedRowData}
              handleStatus={handleStatusButton}
              setRowKey={setSelectedRowKeys}
              tableActionItems={tableActionItems}
              setCandidateSelectedData={setCandidateSelectedData}
              getData={getData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              handleDeleteFunctionality={handleDeleteFunctionality}
            />
          </div>
        )}
        {type === "NOTES" && (
          <div>
            <Notes
              data2={data}
              handleDelete={handleDelete}
              advertId={id}
              setSelectedRowData={setSelectedRowData}
              handleStatus={handleStatusButton}
              setRowKey={setSelectedRowKeys}
              getData={getData}
              tableActionItems={tableActionItems}
              setCandidateSelectedData={setCandidateSelectedData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              handleDeleteFunctionality={handleDeleteFunctionality}
            />
          </div>
        )}
      </div>
      <DeleteAdvertModal
        visible={isModalOpen}
        setVisible={setIsModalOpen}
        text={"Advert"}
        handleSubmit={deleteAdvert}
        loading={deleteLoading}
      />
    </SubLayout>
  );
}

export default TrackerHistory;

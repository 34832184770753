import React, { useState, useEffect } from "react";
import { Row, Col, Button, Menu } from "antd";
import Logo from "../../Assets/login/Logo.svg";
import { useNavigate } from "react-router-dom";
import UpArrow from "../../Assets/whiteUpArrow.svg";
import DownArrow from "../../Assets/whiteDownArrow.svg";
import { useLocation } from "react-router-dom";
import { ValidRoutes } from "../../utils/validRoutes";
function Layout({ children }) {
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const [current, setCurrent] = useState("");
  const location = useLocation();
  const [showMore, setShowMore] = useState(localStorage.getItem("moreOption"));

  useEffect(() => {
    let currentPath =
      location.pathname.split("/")[1] !== ""
        ? location.pathname.split("/")[1]
        : user === "Admin" || user === "Root" || user === "Associate"
        ? ValidRoutes[user][0]
        : user === "Account" && permissions?.includes(0)
        ? ValidRoutes["AccountCampaign"][0]
        : user === "Account" && permissions?.includes(0)
        ? ValidRoutes["AccountUser"][0]
        : "campaign-management"
        ? ValidRoutes["AccountUser"][0]
        : "mobilisation-management";

    if (
      currentPath.includes("campaign") ||
      currentPath.includes("create-advert") ||
      currentPath.includes("advert-detail") ||
      currentPath.includes("applicant-profile")
      // currentPath.includes("mobilisation-management")
    ) {
      setCurrent("mobilisation-management");
      setCurrent("campaign-management");
    } else {
      setCurrent(currentPath);
    }
    // setCurrent(currentPath);
  }, [location]);
  function getItem(label, link, key, icon, children) {
    return {
      key,
      children,
      // icon,
      label: (
        <div
          onClick={() => {
            if (link) {
              navigate(`/${link}`);
            }
          }}
        >
          <div className="mainMenuLink">
            {icon}
            <div className="menu_link">{label}</div>
          </div>
        </div>
      ),
    };
  }
  const items = [];
  if (
    user === "Root" ||
    user === "Admin" ||
    (user === "Account" && permissions?.includes(0))
  ) {
    items.push(
      getItem(
        "Campaign Management",
        "campaign-management",
        "campaign-management",
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 7L11.8845 4.76892C11.5634 4.1268 11.4029 3.80573 11.1634 3.57116C10.9516 3.36373 10.6963 3.20597 10.4161 3.10931C10.0992 3 9.74021 3 9.02229 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V7M2 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V7ZM12 17.5C12 17.5 15 16.0701 15 13.9252V11.4229L12.8124 10.6412C12.2868 10.4529 11.712 10.4529 11.1864 10.6412L9 11.4229V13.9252C9 16.0701 12 17.5 12 17.5Z"
            stroke="#161615"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    );
  }
  //Add new
  // if (
  //   user === "Root" ||
  //   user === "Admin" ||
  //   (user === "Account" && permissions?.includes(3))
  // ) {
  //   items.push(
  //     getItem(
  //       "Mobilisation Management",
  //       "mobilisation-management",
  //       "mobilisation-management",
  //       <svg
  //         width="24"
  //         height="24"
  //         viewBox="0 0 24 24"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <path
  //           d="M13 7L11.8845 4.76892C11.5634 4.1268 11.4029 3.80573 11.1634 3.57116C10.9516 3.36373 10.6963 3.20597 10.4161 3.10931C10.0992 3 9.74021 3 9.02229 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V7M2 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V7ZM12 17.5C12 17.5 15 16.0701 15 13.9252V11.4229L12.8124 10.6412C12.2868 10.4529 11.712 10.4529 11.1864 10.6412L9 11.4229V13.9252C9 16.0701 12 17.5 12 17.5Z"
  //           stroke="#161615"
  //           strokeWidth="2"
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //         />
  //       </svg>
  //     )
  //   );
  // }
  if (
    user === "Root" ||
    user === "Admin" ||
    (user === "Account" && permissions?.includes(3))
  ) {
    items.push(
      getItem(
        "User Management",
        "user-management",
        "user-management",
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 3.46776C17.4817 4.20411 18.5 5.73314 18.5 7.5C18.5 9.26686 17.4817 10.7959 16 11.5322M18 16.7664C19.5115 17.4503 20.8725 18.565 22 20M2 20C3.94649 17.5226 6.58918 16 9.5 16C12.4108 16 15.0535 17.5226 17 20M14 7.5C14 9.98528 11.9853 12 9.5 12C7.01472 12 5 9.98528 5 7.5C5 5.01472 7.01472 3 9.5 3C11.9853 3 14 5.01472 14 7.5Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    );
  }
  if (user === "Root" || user === "Admin") {
    items.push(
      getItem(
        "Subscription Management",
        "subscription-management",
        "subscription-management",
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2H3.30616C3.55218 2 3.67519 2 3.77418 2.04524C3.86142 2.08511 3.93535 2.14922 3.98715 2.22995C4.04593 2.32154 4.06333 2.44332 4.09812 2.68686L4.57143 6M4.57143 6L5.62332 13.7314C5.75681 14.7125 5.82355 15.2031 6.0581 15.5723C6.26478 15.8977 6.56108 16.1564 6.91135 16.3174C7.30886 16.5 7.80394 16.5 8.79411 16.5H17.352C18.2945 16.5 18.7658 16.5 19.151 16.3304C19.4905 16.1809 19.7818 15.9398 19.9923 15.6342C20.2309 15.2876 20.3191 14.8247 20.4955 13.8988L21.8191 6.94969C21.8812 6.62381 21.9122 6.46087 21.8672 6.3335C21.8278 6.22177 21.7499 6.12768 21.6475 6.06802C21.5308 6 21.365 6 21.0332 6H4.57143ZM10 21C10 21.5523 9.55228 22 9 22C8.44772 22 8 21.5523 8 21C8 20.4477 8.44772 20 9 20C9.55228 20 10 20.4477 10 21ZM18 21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21C16 20.4477 16.4477 20 17 20C17.5523 20 18 20.4477 18 21Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    );
  }
  if (user === "Associate") {
    items.push(
      getItem(
        "Job Advert",
        "job-advert",
        "job-advert",
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M13 7L11.8845 4.76892C11.5634 4.1268 11.4029 3.80573 11.1634 3.57116C10.9516 3.36373 10.6963 3.20597 10.4161 3.10931C10.0992 3 9.74021 3 9.02229 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V7M2 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V16.2C22 17.8802 22 18.7202 21.673 19.362C21.3854 19.9265 20.9265 20.3854 20.362 20.673C19.7202 21 18.8802 21 17.2 21H6.8C5.11984 21 4.27976 21 3.63803 20.673C3.07354 20.3854 2.6146 19.9265 2.32698 19.362C2 18.7202 2 17.8802 2 16.2V7ZM12 17.5C12 17.5 15 16.0701 15 13.9252V11.4229L12.8124 10.6412C12.2868 10.4529 11.712 10.4529 11.1864 10.6412L9 11.4229V13.9252C9 16.0701 12 17.5 12 17.5Z"
            stroke="#071135"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )
    );
  }
  items.push(
    getItem(
      "News Feed",
      "news-feed",
      "news-feed",
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 20H5.2C4.07989 20 3.51984 20 3.09202 19.782C2.71569 19.5903 2.40973 19.2843 2.21799 18.908C2 18.4802 2 17.9201 2 16.8V7.2C2 6.07989 2 5.51984 2.21799 5.09202C2.40973 4.71569 2.71569 4.40973 3.09202 4.21799C3.51984 4 4.07989 4 5.2 4H5.6C7.84021 4 8.96031 4 9.81596 4.43597C10.5686 4.81947 11.1805 5.43139 11.564 6.18404C12 7.03968 12 8.15979 12 10.4M12 20V10.4M12 20H18.8C19.9201 20 20.4802 20 20.908 19.782C21.2843 19.5903 21.5903 19.2843 21.782 18.908C22 18.4802 22 17.9201 22 16.8V7.2C22 6.07989 22 5.51984 21.782 5.09202C21.5903 4.71569 21.2843 4.40973 20.908 4.21799C20.4802 4 19.9201 4 18.8 4H18.4C16.1598 4 15.0397 4 14.184 4.43597C13.4314 4.81947 12.8195 5.43139 12.436 6.18404C12 7.03968 12 8.15979 12 10.4"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  );

  return (
    <div className="layout_main_component">
      <div style={{ display: "flex" }}>
        <div className="sidebar-menu">
          <div style={{ maxWidth: "300px", minWidth: "300px" }}>
            <div className="mainSidebar">
              <div>
                <div
                  style={{
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    onClick={() => {
                      user === "Associate"
                        ? navigate("/job-advert")
                        : permissions.includes(0)
                        ? navigate("/campaign-management")
                        : permissions.includes(0)
                        ? navigate("/mobilisation-management")
                        : navigate("/user-management");
                    }}
                    src={Logo}
                    alt="logo"
                    className="cursor-pointer"
                    style={{ marginLeft: "3%" }}
                  />
                </div>

                <Menu
                  mode="inline"
                  defaultSelectedKeys={[current]}
                  selectedKeys={[current]}
                  style={{
                    height: "100%",
                    borderRight: 0,
                  }}
                  items={items}
                />

                <Row gutter={8} align={"middle"} className="moreBtnRow">
                  <Col
                    onClick={() => {
                      setShowMore(!showMore);
                      if (localStorage.getItem("moreOption") === "close") {
                        localStorage.setItem("moreOption", "open");
                      } else {
                        localStorage.setItem("moreOption", "close");
                      }
                    }}
                  >
                    <img
                      src={
                        localStorage.getItem("moreOption") === "open"
                          ? UpArrow
                          : DownArrow
                      }
                      className="cursor-pointer"
                    />
                  </Col>
                  <Col
                    onClick={() => {
                      setShowMore(!showMore);
                      if (localStorage.getItem("moreOption") === "close") {
                        localStorage.setItem("moreOption", "open");
                      } else {
                        localStorage.setItem("moreOption", "close");
                      }
                    }}
                    className="moreBtn cursor-pointer"
                  >
                    More
                  </Col>
                  {localStorage.getItem("moreOption") === "open" && (
                    <Col span={24} className="showMoreList">
                      <p
                        className={`moreLink ${
                          current === "about-us" ? "activeMenuLink" : ""
                        }`}
                        onClick={() => navigate("/about-us")}
                      >
                        About Us
                      </p>
                      <p
                        onClick={() => navigate("/terms-of-use")}
                        className={`moreLink ${
                          current === "terms-of-use" ? "activeMenuLink" : ""
                        }`}
                      >
                        Terms & Conditions
                      </p>
                      <p
                        className={`moreLink ${
                          current === "privacy-policy" ? "activeMenuLink" : ""
                        }`}
                        onClick={() => navigate("/privacy-policy")}
                      >
                        Privacy Policy
                      </p>
                      <p
                        className={`moreLink ${
                          current === "contact-us" ? "activeMenuLink" : ""
                        }`}
                        onClick={() => navigate("/contact-us")}
                      >
                        Contact Us
                      </p>
                      {localStorage.getItem("user") !== "Associate" && (
                        <p
                          className={`moreLink ${
                            current === "archive" ? "activeMenuLink" : ""
                          }`}
                          onClick={() => navigate("/archive")}
                        >
                          Archived Campaigns
                        </p>
                      )}
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100">{children}</div>
      </div>
    </div>
  );
}

export default Layout;

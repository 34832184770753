import React, { useState, useEffect, useCallback } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Button,
  Space,
  Typography,
  Popover,
  Checkbox,
  Divider,
  Radio,
  Modal,
  Select,
  Row,
  Col,
  Calendar,
  Avatar,
} from "antd";
import {
  SearchOutlined,
  EyeInvisibleOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteFilled,
  AppstoreAddOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import FullScreenLoader from "../../components/common/FullScreenLoader";
import { Table, Badge, Tag, Dropdown } from "antd";
import {
  EyeOutlined,
  LeftOutlined,
  DownOutlined,
  InfoCircleOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  PercentageOutlined,
  CalendarOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// Status Icons
const statusIcons = {
  success: <CheckCircleFilled style={{ color: "#10B981", fontSize: 18 }} />,
  warning: (
    <ExclamationCircleFilled style={{ color: "#FACC15", fontSize: 18 }} />
  ),
  error: <CloseCircleFilled style={{ color: "#EF4444", fontSize: 18 }} />,
};

// Function to handle column drag and drop

const options = [
  { label: "Calendar", icon: <CalendarOutlined /> },
  { label: "Pick List", icon: <OrderedListOutlined /> },
  { label: "Yes/No", icon: <PercentageOutlined /> },
];

const columnData = [
  {
    category: "Personal details",
    columns: [
      { key: "email", label: "Email", checked: true },
      { key: "photo", label: "Photo", checked: true },
      { key: "name", label: "Name", checked: true },
      { key: "dob", label: "DOB", checked: false },
      { key: "gender", label: "Gender", checked: false },
      { key: "ethnicity", label: "Ethnicity", checked: false, disabled: true },
      { key: "phone", label: "Phone number", checked: true },
    ],
  },
  {
    category: "Other details",
    columns: [
      { key: "address", label: "Address", checked: false },
      { key: "lsl", label: "LSL", checked: false },
      { key: "ppe", label: "PPE", checked: true },
      {
        key: "police",
        label: "Police clearance",
        checked: false,
        disabled: true,
      },
    ],
  },
];

const medicalStatusMenu = (
  <ul
    style={{
      listStyle: "none",
      padding: 5,
      margin: 0,
      background: "white",
      boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
      borderRadius: 5,
    }}
  >
    <li style={{ padding: "5px 10px", cursor: "pointer" }}>
      {statusIcons.success} Approved
    </li>
    <li style={{ padding: "5px 10px", cursor: "pointer" }}>
      {statusIcons.warning} Pending
    </li>
    <li style={{ padding: "5px 10px", cursor: "pointer" }}>
      {statusIcons.error} Rejected
    </li>
  </ul>
);
const sortByDate = (a, b, dataIndex) => {
  const dateA = new Date(a[dataIndex]);
  const dateB = new Date(b[dataIndex]);
  return dateA - dateB;
};

// Sorting Function for Text Fields
const sortByText = (a, b, dataIndex) =>
  a[dataIndex].localeCompare(b[dataIndex]);

const data = [
  {
    key: "1",
    name: "Kendrick Miller",
    startDate: "12 Nov, 2024",
    ppe: "warning",
    damstraReg: "success",
    jobTitle: "Electrician",
    photoID: "error",
    medicalStatus: "28/11/24",
    visa: "warning",
  },
  {
    key: "2",
    name: "John Dae",
    startDate: "12 Nov, 2024",
    ppe: "error",
    damstraReg: "warning",
    jobTitle: "Plumber",
    photoID: "warning",
    medicalStatus: "31/11/24",
    visa: "success",
  },
  {
    key: "3",
    name: "Emily Carter",
    startDate: "05 Oct, 2024",
    ppe: "success",
    damstraReg: "error",
    jobTitle: "Welder",
    photoID: "success",
    medicalStatus: "15/12/24",
    visa: "success",
  },
  {
    key: "4",
    name: "Michael Lee",
    startDate: "20 Sep, 2024",
    ppe: "error",
    damstraReg: "success",
    jobTitle: "Carpenter",
    photoID: "warning",
    medicalStatus: "22/11/24",
    visa: "error",
  },
  {
    key: "5",
    name: "Sophia Turner",
    startDate: "18 Aug, 2024",
    ppe: "warning",
    damstraReg: "success",
    jobTitle: "Painter",
    photoID: "error",
    medicalStatus: "10/12/24",
    visa: "warning",
  },
  {
    key: "6",
    name: "Daniel Smith",
    startDate: "30 Jul, 2024",
    ppe: "success",
    damstraReg: "warning",
    jobTitle: "Rigger",
    photoID: "success",
    medicalStatus: "05/12/24",
    visa: "success",
  },
];

// Table Columns

const filterData = [
  {
    label: "Campaign ABC",
    key: "campaign_abc",
    children: [
      {
        label: "Westconnex",
        key: "westconnex",
        children: [
          { label: "Electrician", key: "electrician" },
          { label: "HV Electrician", key: "hv_electrician" },
          { label: "Trade Assistant", key: "trade_assistant" },
        ],
      },
    ],
  },
  {
    label: "Campaign PQR",
    key: "campaign_pqr",
  },
];

function MobilisationManagement() {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("manual"); // Track active tab
  const [type, setType] = useState("Calendar"); // Track selected type
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");

  const [search, setSearch] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [visible, setVisible] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [columnsvisible, setcolumnsvisible] = useState(columnData);
  const [visibleSort, setvisibleSort] = useState();
  const [sortValue, setSortValue] = useState("recently_added");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddGateModalOpen, setIsAddGateModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  console.log(popoverVisible, "TESTPOP");

  const [selectedType, setSelectedType] = useState("Calendar");

  const [selectedDate, setSelectedDate] = useState(null);

  // Mock data for events/avatars
  const events = {
    "2024-03-17": [
      { name: "User 1", avatar: "https://i.pravatar.cc/40?img=1" },
      { name: "User 2", avatar: "https://i.pravatar.cc/40?img=2" },
    ],
    "2024-03-20": [
      { name: "User 3", avatar: "https://i.pravatar.cc/40?img=3" },
    ],
  };

  // Handle click event
  const handleDateClick = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // if (events[formattedDate]) {
    //   setSelectedDate(formattedDate);
    //   setPopoverVisible(!popoverVisible); // Toggle popover
    // } else {
    //   setPopoverVisible(false); // Close if no event
    // }
  };

  // Close popover when clicking outside
  const handleClickOutside = () => {
    setPopoverVisible(false);
  };

  const handleButtonClick = (label) => {
    setSelectedType(label);
    if (label === "Calendar") {
      setPopoverVisible(!popoverVisible); // Toggle popover
    }
  };

  // Render date cells with popover
  const dateCellRender = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    // return (
    //   eventData && (
    //     <Popover
    //       content={
    //         <div style={{ display: "flex", gap: "6px" }}>
    //           {eventData.map((user, index) => (
    //             <Avatar key={index} src={user.avatar} />
    //           ))}
    //         </div>
    //       }
    //       title="Attendees"
    //       trigger="click"
    //       open={popoverVisible} // Open only on click
    //       onOpenChange={handleClickOutside} // Close on outside click
    //     >
    //       <div
    //         onClick={() => handleDateClick(date)}
    //         style={{ cursor: "pointer" }}
    //       >
    //         <Badge dot color="blue" />
    //       </div>
    //     </Popover>
    //   )
    // );
  };

  const openAddGateModal = () => {
    setIsAddGateModalOpen(true);
  };

  const closeAddGateModal = () => {
    setIsAddGateModalOpen(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    console.log("Deleted!"); // Perform delete action here
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSortChange = (e) => {
    setSortValue(e.target.value);
  };

  const [checkedKeys, setCheckedKeys] = useState([]);

  const contentSort = (
    <div
      style={{ width: 180, background: "#fff", padding: 12, borderRadius: 8 }}
    >
      <h4
        style={{
          fontSize: 14,
          fontWeight: 600,
          marginBottom: 10,
          color: "black",
        }}
      >
        SORT LIST
      </h4>
      <Radio.Group
        onChange={handleSortChange}
        value={sortValue}
        style={{ display: "flex", flexDirection: "column", gap: 6 }}
      >
        <Radio value="recently_added" style={{ fontSize: 13 }}>
          Recently Added
        </Radio>
        <Radio value="alphabetical" style={{ fontSize: 13 }}>
          Alphabetical
        </Radio>
        <Radio value="date_range" style={{ fontSize: 13 }}>
          Date Range
        </Radio>
      </Radio.Group>
    </div>
  );

  const handleCheck = (key) => {
    setCheckedKeys((prev) =>
      prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]
    );
  };
  const renderTree = (items, level = 0) =>
    items.map((item) => (
      <div
        key={item.key}
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: level * 15, // Controls indentation
          paddingBottom: 5,
        }}
      >
        {item.children ? (
          <DownOutlined style={{ fontSize: 12, marginRight: 5 }} />
        ) : (
          <span style={{ width: 17 }} /> // Keeps alignment consistent for all rows
        )}
        <Checkbox
          checked={checkedKeys.includes(item.key)}
          onChange={() => handleCheck(item.key)}
          style={{ fontSize: 14 }}
        >
          {item.label}
        </Checkbox>
      </div>
    ));

  const toggleVisibility = (key) => {
    setcolumnsvisible((prev) =>
      prev.map((section) => ({
        ...section,
        columnsvisible: section.columnsvisible.map((col) =>
          col.key === key ? { ...col, checked: !col.checked } : col
        ),
      }))
    );
  };

  const contentFilter = (
    <div
      style={{ width: 260, background: "#fff", borderRadius: 8, padding: 15 }}
    >
      <h4
        style={{
          fontSize: 14,
          fontWeight: 600,
          marginBottom: 8,
          color: "black",
        }}
      >
        FILTERS
      </h4>
      <div
        style={{
          fontSize: 12,
          fontWeight: 600,
          marginBottom: 6,
          color: "black",
        }}
      >
        CAMPAIGNS & ADVERTS
      </div>
      <div
        style={{
          color: "#3B82F6",
          textDecoration: "underline",
          cursor: "pointer",
          fontSize: 12,
          marginBottom: 8,
        }}
        onClick={() => setCheckedKeys([])}
      >
        Show All
      </div>
      {renderTree(filterData)}
    </div>
  );

  const content = (
    <div
      style={{ width: 250, background: "#fff", borderRadius: 8, padding: 10 }}
    >
      <h4
        style={{
          marginBottom: 10,
          fontSize: 14,
          fontWeight: 600,
          color: "black",
        }}
      >
        SHOW/HIDE COLUMNS
      </h4>
      <input
        placeholder="Search"
        style={{
          width: "100%",
          padding: "6px 10px",
          border: "1px solid #ddd",
          borderRadius: 4,
          marginBottom: 8,
        }}
      />
      {columnsvisible.map((section, idx) => (
        <div key={idx}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontWeight: 600,
              fontSize: 12,
              marginBottom: 5,
            }}
          >
            {section.category} <DownOutlined style={{ fontSize: 10 }} />
          </div>
          {section.columns.map((col) => (
            <div
              key={col.key}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px 0",
                opacity: col.disabled ? 0.5 : 1,
              }}
            >
              <Checkbox
                checked={col.checked}
                onChange={() => !col.disabled && toggleVisibility(col.key)}
                disabled={col.disabled}
              >
                {col.label}
              </Checkbox>
              <span style={{ cursor: "pointer", color: "#999" }}>✖</span>
            </div>
          ))}
          {idx !== columnsvisible.length - 1 && (
            <Divider style={{ margin: "8px 0" }} />
          )}
        </div>
      ))}
    </div>
  );

  // const [columns, setColumns] = useState([
  //   {
  //     title: "Details",
  //     dataIndex: "name",
  //     key: "name",
  //     sorter: (a, b) => sortByText(a, b, "name"),
  //     render: (text, record) => (
  //       <div
  //         style={{
  //           display: "flex",
  //           justifyContent: "space-between",
  //           alignItems: "center",
  //           padding: "12px",
  //           borderRadius: "8px",
  //           backgroundColor: "#F8FAFC",
  //           border: "1px solid #E2E8F0",
  //           minWidth: "200px",
  //         }}
  //       >
  //         {/* Name and Start Date */}
  //         <div>
  //           <div
  //             style={{ fontWeight: 600, color: "#1E40AF", fontSize: "14px" }}
  //           >
  //             {text}
  //           </div>
  //           <div
  //             style={{ fontSize: "12px", color: "#64748B", marginTop: "2px" }}
  //           >
  //             Start Date: {record.startDate}
  //           </div>
  //         </div>

  //         {/* Icons */}
  //         <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
  //           <EyeOutlined
  //             style={{ color: "#3B82F6", fontSize: "16px", cursor: "pointer" }}
  //           />
  //           <InfoCircleOutlined
  //             style={{ color: "#3B82F6", fontSize: "16px", cursor: "pointer" }}
  //             onClick={() => setSelectedEmployee(record)}
  //           />
  //         </div>
  //       </div>
  //     ),
  //   },

  //   {
  //     title: "PPE",
  //     dataIndex: "ppe",
  //     key: "ppe",
  //     align: "center",

  //     sorter: (a, b) => sortByText(a, b, "ppe"),
  //     render: (status) => <span>{statusIcons[status]}</span>,
  //   },
  //   {
  //     title: " DAMSTRA Reg",
  //     dataIndex: "damstraReg",
  //     key: "damstraReg",
  //     align: "center",
  //     sorter: (a, b) => sortByText(a, b, "damstraReg"),
  //     render: (status) => <span>{statusIcons[status]}</span>,
  //   },
  //   {
  //     title: "Job Title",
  //     dataIndex: "jobTitle",
  //     key: "jobTitle",
  //     align: "center",
  //     sorter: (a, b) => sortByText(a, b, "jobTitle"),
  //     render: (text) => <span style={{ color: "black" }}>{text}</span>,
  //   },
  //   {
  //     title: "Photo ID",
  //     dataIndex: "photoID",
  //     key: "photoID",
  //     align: "center",
  //     sorter: (a, b) => sortByText(a, b, "photoID"),
  //     render: (status) => <span>{statusIcons[status]}</span>,
  //   },
  //   {
  //     title: "Medical Status",

  //     dataIndex: "medicalStatus",
  //     key: "medicalStatus",
  //     align: "center",
  //     sorter: (a, b) => sortByDate(a, b, "medicalStatus"),
  //     render: (text, record) => (
  //       <div
  //         style={{
  //           display: "flex",
  //           alignItems: "center",
  //           gap: 10,
  //           marginLeft: "50px",
  //         }}
  //       >
  //         {/* Date formatted to DD/MM/YY */}
  //         <span style={{ color: "#1E40AF", fontWeight: 500 }}>{text} </span>

  //         {/* Dropdown with green check icon */}
  //         <Dropdown overlay={medicalStatusMenu} trigger={["click"]}>
  //           <div
  //             style={{
  //               display: "flex",
  //               alignItems: "center",
  //               gap: "5px",
  //               background: "#F8FAFC",
  //               borderRadius: "6px",
  //               border: "1px solid #E2E8F0",
  //               padding: "5px 10px",
  //               cursor: "pointer",
  //             }}
  //           >
  //             <CheckCircleFilled style={{ color: "#10B981", fontSize: 16 }} />
  //             <DownOutlined style={{ fontSize: 12, color: "#64748B" }} />
  //           </div>
  //         </Dropdown>
  //       </div>
  //     ),
  //   },

  //   {
  //     title: "Visa",
  //     dataIndex: "visa",
  //     key: "visa",
  //     align: "center",
  //     sorter: (a, b) => sortByText(a, b, "visa"),
  //     render: (status) => <span>{statusIcons[status]}</span>,
  //   },
  // ]);

  const initialColumns = [
    {
      title: "Details",
      dataIndex: "name",
      // fixed: "left",
      key: "name",
      fixed: "left",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px",
            borderRadius: "8px",
            backgroundColor: "#F8FAFC",
            border: "1px solid #E2E8F0",
            minWidth: "200px",
          }}
        >
          <div>
            <div
              style={{ fontWeight: 600, color: "#1E40AF", fontSize: "14px" }}
            >
              {text}
            </div>
            <div
              style={{ fontSize: "12px", color: "#64748B", marginTop: "2px" }}
            >
              Start Date: {record.startDate}
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <EyeOutlined
              style={{ color: "#3B82F6", fontSize: "16px", cursor: "pointer" }}
            />
            <InfoCircleOutlined
              style={{ color: "#3B82F6", fontSize: "16px", cursor: "pointer" }}
              onClick={() => console.log("Info clicked", record)}
            />
          </div>
        </div>
      ),
    },
    {
      title: "PPE",
      dataIndex: "ppe",
      key: "ppe",
      align: "center",
      sorter: (a, b) => a.ppe.localeCompare(b.ppe),
      render: (status) => <span>{statusIcons[status]}</span>,
    },
    {
      title: "DAMSTRA Reg",
      dataIndex: "damstraReg",
      key: "damstraReg",
      align: "center",
      sorter: (a, b) => a.damstraReg.localeCompare(b.damstraReg),
      render: (status) => <span>{statusIcons[status]}</span>,
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      align: "center",
      sorter: (a, b) => a.jobTitle.localeCompare(b.jobTitle),
      render: (text) => <span style={{ color: "black" }}>{text}</span>,
    },
    {
      title: "Photo ID",
      dataIndex: "photoID",
      key: "photoID",
      align: "center",
      sorter: (a, b) => a.photoID.localeCompare(b.photoID),
      render: (status) => <span>{statusIcons[status]}</span>,
    },
    {
      title: "Medical Status",
      dataIndex: "medicalStatus",
      key: "medicalStatus",
      align: "center",
      sorter: (a, b) => a.medicalStatus.localeCompare(b.medicalStatus),
      render: (text) => (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <span style={{ color: "#1E40AF", fontWeight: 500 }}>{text} </span>
          <Dropdown overlay={<div>Dropdown Menu</div>} trigger={["click"]}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                background: "#F8FAFC",
                borderRadius: "6px",
                border: "1px solid #E2E8F0",
                padding: "5px 10px",
                cursor: "pointer",
              }}
            >
              <CheckCircleFilled style={{ color: "#10B981", fontSize: 16 }} />
              <DownOutlined style={{ fontSize: 12, color: "#64748B" }} />
            </div>
          </Dropdown>
        </div>
      ),
    },
    // {
    //   title: "Visa",
    //   dataIndex: "visa",
    //   key: "visa",
    //   align: "center",
    //   sorter: (a, b) => a.visa.localeCompare(b.visa),
    //   render: (status) => (
    //     <div style={{ marginRight: "100px" }}>
    //       <span>{statusIcons[status]}</span>
    //     </div>
    //   ),
    // },
  ];

  const [columns, setColumns] = useState(initialColumns);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      const reorderedColumns = Array.from(columns);
      const [movedColumn] = reorderedColumns.splice(result.source.index, 1);
      reorderedColumns.splice(result.destination.index, 0, movedColumn);
      setColumns(reorderedColumns);
    },
    [columns]
  );

  // const handleDragEnd = (result) => {
  //   if (!result.destination) return;

  //   const reorderedColumns = [...columns];
  //   const [movedColumn] = reorderedColumns.splice(result.source.index, 1);
  //   reorderedColumns.splice(result.destination.index, 0, movedColumn);
  //   setColumns(reorderedColumns);
  // };

  //     title: "Details",
  //     dataIndex: "name",
  //     key: "name",
  //     render: (text, record) => (
  //       <Space>
  //         <EyeOutlined style={{ cursor: "pointer" }} />
  //         <span>{text}</span>
  //         <small style={{ color: "gray" }}>
  //           Start Date: {record.startDate}
  //         </small>
  //       </Space>
  //     ),
  //   },
  //   {
  //     title: "PPE",
  //     dataIndex: "ppe",
  //     key: "ppe",
  //     render: getBadge,
  //   },
  //   {
  //     title: "DAMSTRA Reg",
  //     dataIndex: "damstra",
  //     key: "damstra",
  //     render: getStatusTag,
  //   },
  //   {
  //     title: "LSL",
  //     dataIndex: "lsl",
  //     key: "lsl",
  //     render: getBadge,
  //   },
  //   {
  //     title: "Photo ID",
  //     dataIndex: "photoID",
  //     key: "photoID",
  //     render: getBadge,
  //   },
  //   {
  //     title: "Medical Status",
  //     dataIndex: "medicalStatus",
  //     key: "medicalStatus",
  //     render: (text) => <span>{text}</span>,
  //   },
  //   {
  //     title: "Visa",
  //     dataIndex: "visa",
  //     key: "visa",
  //     render: getBadge,
  //   },
  // ];

  return (
    <SubLayout page={"Mobilisation Management"} whiteBg showSearch>
      {!search && <FullScreenLoader isLoading={loading} />}
      <div style={{ padding: 20 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "10px",
            padding: "10px",
          }}
        >
          <div style={{ flex: "1 1 auto", minWidth: "250px" }}>
            <h2 style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <LeftOutlined />
              Mobilisation Management
            </h2>
            <p style={{ color: "gray", margin: 0 }}>
              Customise the requirements for shortlisted candidates
            </p>
          </div>

          <div>
            <Button
              type="primary"
              style={{
                background: "#d19a40",
                border: "none",
                height: "50px",
                width: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={openAddGateModal}
            >
              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "black",
                  padding: "10px 20px",
                  margin: 0,
                }}
              >
                + Add Gate
              </h3>
            </Button>
            <Modal
              title="New Gate"
              width={830}
              open={isAddGateModalOpen}
              onCancel={closeAddGateModal}
              // bodyStyle={{ height: "auto" }}
              centered
              footer={
                <Row
                  justify="space-between"
                  style={{ width: "100%", flexWrap: "wrap", gap: "10px" }}
                >
                  <Col xs={24} sm={12} md={6}>
                    <Button
                      onClick={closeAddGateModal}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                        border: "1px solid #122D8E",
                        background: "#FFF",
                        boxShadow: "0px 1px 8px -4px rgba(0, 0, 0, 0.12)",
                        color: "#071135", // Text color
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 700, // Bold
                        lineHeight: "normal",
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Button
                      type="primary"
                      onClick={closeAddGateModal}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "8px",
                        backgroundColor: "#F9CF66",
                        border: "none",
                        color: "#091647", // Text color
                        textAlign: "center",
                        fontFamily: "Arial",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 700, // Bold
                        lineHeight: "normal",
                      }}
                    >
                      Save
                    </Button>
                  </Col>

                  {activeTab === "manual" && (
                    <Col xs={24} sm={12} md={6}>
                      <Button
                        style={{
                          width: "100%",
                          height: "40px",
                          borderRadius: "8px",
                          border: "1px solid #122D8E",
                          background: "#FFF",
                          boxShadow: "0px 1px 8px -4px rgba(0, 0, 0, 0.12)",
                          color: "#071135", // Text color
                          textAlign: "center",
                          fontFamily: "Arial",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 700, // Bold
                          lineHeight: "normal",
                        }}
                      >
                        Assign
                      </Button>
                    </Col>
                  )}
                </Row>
              }
            >
              {/* Tabs */}
              <div
                style={{
                  display: "flex",
                  gap: "12px",
                  fontWeight: "bold",
                  flexWrap: "wrap",
                  // justifyContent: "center",
                }}
              >
                <span
                  style={{
                    color: activeTab === "auto" ? "#000" : "#A0A0A0",
                    cursor: "pointer",
                  }}
                  onClick={() => setActiveTab("auto")}
                >
                  AUTO POPULATED
                </span>
                <span
                  style={{
                    color: activeTab === "manual" ? "#000" : "#A0A0A0",
                    cursor: "pointer",
                  }}
                  onClick={() => setActiveTab("manual")}
                >
                  MANUAL CREATION
                </span>
              </div>

              {/* Auto-Populated Content */}
              {activeTab === "auto" && (
                <>
                  <label style={{ fontWeight: "bold", display: "block" }}>
                    Category <span style={{ color: "#007bff" }}>ⓘ</span>
                  </label>
                  <Select
                    placeholder="Choose Category"
                    style={{ width: "100%", marginTop: "8px" }}
                  >
                    <Select.Option value="personal">
                      Personal Details
                    </Select.Option>
                    <Select.Option value="medical">
                      Medical Records
                    </Select.Option>
                  </Select>
                </>
              )}

              {/* Manual Creation Content */}
              {activeTab === "manual" && (
                <Row gutter={16} style={{ flexWrap: "wrap" }}>
                  <Col xs={24} md={12}>
                    <label style={{ fontWeight: "bold", display: "block" }}>
                      Name
                    </label>
                    <Input
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{ marginBottom: "16px" }}
                    />

                    <div>
                      <label
                        style={{
                          fontWeight: "bold",
                          display: "block",
                          marginBottom: "8px",
                        }}
                      >
                        Type
                      </label>
                      <div
                        style={{
                          display: "flex",
                          gap: "12px",
                          marginBottom: "16px",
                          // flexWrap: "wrap",
                        }}
                      >
                        {options.map((option) => (
                          <Button
                            type={
                              selectedType === option.label
                                ? "primary"
                                : "default"
                            }
                            onClick={() => handleButtonClick(option.label)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              borderRadius: "8px",
                              padding: "12px",
                              flex: "1 0 auto",
                              minWidth: "120px",
                              textAlign: "center",
                              border:
                                selectedType === option.label
                                  ? "2px solid #122D8E"
                                  : "1px solid #122D8E",
                              backgroundColor:
                                selectedType === option.label
                                  ? "#F7F9FF"
                                  : "transparent",
                              color: "#122D8E",
                            }}
                          >
                            {option.icon} {option.label}
                            {selectedType === option.label && (
                              <EditOutlined style={{ marginLeft: "6px" }} />
                            )}
                          </Button>
                        ))}
                      </div>
                      {popoverVisible && (
                        <div
                          style={{
                            width: "307.5px",
                            height: "182.567px",
                            position: "absolute",
                            left: "20px",
                            // bottom: "-0.167px",
                            zIndex: 9999,
                            // backgroundColor: "white",
                          }}
                        >
                          {" "}
                          {/* Adjust width as needed */}
                          <Calendar
                            onSelect={handleDateClick}
                            dateCellRender={dateCellRender}
                            fullscreen={false}
                            headerRender={() => null} // Hides the header
                          />
                        </div>
                      )}
                    </div>

                    <label style={{ fontWeight: "bold", display: "block" }}>
                      Category <span style={{ color: "#007bff" }}>ⓘ</span>
                    </label>
                    <Select
                      placeholder="Choose Category"
                      value={category}
                      onChange={setCategory}
                      style={{ width: "100%", marginTop: "8px" }}
                    >
                      <Select.Option value="personal">
                        Personal Details
                      </Select.Option>
                      <Select.Option value="medical">
                        Medical Records
                      </Select.Option>
                    </Select>
                  </Col>

                  {/* Preview Section */}
                  <Col
                    xs={24}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "16px",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #ddd",
                        padding: "20px",
                        borderRadius: "12px",
                        background: "#fff",
                        width: "100%",

                        maxWidth: "300px",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        textAlign: "center",
                      }}
                    >
                      <h4 style={{ marginBottom: "12px", color: "#333" }}>
                        PREVIEW
                      </h4>
                      <div
                        style={{
                          border: "1px solid #eee",
                          padding: "12px",
                          borderRadius: "12px",
                          background: "#fff",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
                          width: "100%",
                          // maxWidth: "200px",
                        }}
                      >
                        {/* Label with Icon */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            marginBottom: "8px",
                            background: "#fff",
                            borderRadius: "6px",
                            padding: "6px",
                            border: "1px solid #ddd",
                            fontWeight: "bold",
                          }}
                        >
                          <MenuOutlined
                            style={{ fontSize: "16px", color: "#666" }}
                          />
                          <span style={{ flex: 1 }}>Medical Status</span>
                        </div>

                        {/* Input with Red Icon Dropdown */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid #ddd",
                            borderRadius: "6px",
                            overflow: "hidden",
                          }}
                        >
                          <Input
                            placeholder="16/12/24"
                            style={{
                              textAlign: "center",
                              border: "none",
                              flex: 1,
                              padding: "6px",
                            }}
                            disabled
                          />
                          <Select
                            defaultValue="red"
                            style={{
                              border: "none",
                              width: "50px",
                              textAlign: "center",
                            }}
                          >
                            <Select.Option value="red">
                              <CloseCircleFilled style={{ color: "red" }} />
                            </Select.Option>
                            <Select.Option value="other">Other</Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Modal>
          </div>

          {/* Responsive adjustments */}
          <style>
            {`
          @media (max-width: 600px) {
            div {
              flex-direction: column;
              align-items: flex-start;
              text-align: left;
            }
          }
        `}
          </style>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
            marginTop: 40,
          }}
        >
          {/* Left Section */}
          <div>
            <Typography.Text style={{ fontSize: 16, fontWeight: 600 }}>
              Westconnex
            </Typography.Text>
            <br />
            <Typography.Text
              style={{
                fontSize: 12,
                color: "#3B82F6",
                background: "#EEF4FF",
                padding: "2px 6px",
                borderRadius: 10,
                fontWeight: 500,
              }}
            >
              +2
            </Typography.Text>
            <Typography.Text style={{ fontSize: 12, color: "#64748B" }}>
              Electrician | HV Electrician | Trade Assistant
            </Typography.Text>
          </div>

          {/* Right Section */}
          <Space size="middle">
            {/* Search Box */}
            <Input
              prefix={<SearchOutlined style={{ color: "#3B82F6" }} />}
              placeholder="Search"
              style={{
                width: 200,
                borderRadius: 6,
                backgroundColor: "#F8FAFC",
                border: "1px solid #E2E8F0",
              }}
            />

            {/* Buttons */}
            <Popover
              content={content}
              trigger="click"
              placement="bottomLeft"
              open={visible}
              onOpenChange={setVisible}
              overlayStyle={{
                borderRadius: 8,
                boxShadow: "0 4px 10px rgba(0,0,0,0.15)",
              }}
              overlayInnerStyle={{ backgroundColor: "#fff", padding: "0px" }}
            >
              <Button
                icon={<EyeInvisibleOutlined style={{ color: "#3B82F6" }} />}
                style={{
                  background: "#EEF4FF",
                  color: "#3B82F6",
                  border: "none",
                  fontWeight: 500,
                }}
              >
                Show/Hide
              </Button>
            </Popover>
            <Popover
              content={contentFilter}
              trigger="click"
              placement="bottomLeft"
              open={visibleFilter}
              onOpenChange={setVisibleFilter}
              overlayStyle={{
                borderRadius: 8,
                boxShadow: "0 4px 10px rgba(0,0,0,0.15)",
              }}
              overlayInnerStyle={{ backgroundColor: "#fff", padding: "0px" }}
            >
              <Button
                icon={<FilterOutlined style={{ color: "#3B82F6" }} />}
                style={{
                  background: "#EEF4FF",
                  color: "#3B82F6",
                  border: "none",
                  fontWeight: 500,
                }}
              >
                Filter By
              </Button>
            </Popover>
            <Popover
              content={contentSort}
              trigger="click"
              placement="bottomLeft"
              open={visibleSort}
              onOpenChange={setvisibleSort}
              overlayStyle={{
                borderRadius: 8,
                boxShadow: "0 4px 10px rgba(0,0,0,0.15)",
              }}
              overlayInnerStyle={{ backgroundColor: "#fff", padding: "0px" }}
            >
              <Button
                icon={<EditOutlined style={{ color: "#3B82F6" }} />}
                style={{
                  background: "#EEF4FF",
                  color: "#3B82F6",
                  border: "none",
                  fontWeight: 500,
                }}
              >
                Sort
              </Button>
            </Popover>
          </Space>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="tableColumns"
            direction="horizontal"
            type="column"
          >
            {(provided) => (
              <Table
                {...provided.droppableProps}
                ref={provided.innerRef}
                dataSource={data}
                columns={columns.map((col, index) => ({
                  ...col,

                  title: (
                    <Draggable
                      key={col.key}
                      draggableId={col.key}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                          }}
                        >
                          <MenuOutlined style={{ cursor: "grab" }} />
                          {col.title}
                        </div>
                      )}
                    </Draggable>
                  ),
                }))}
                pagination={{ pageSize: 5 }}
                scroll={{ x: 1550, y: 500 }}
              />
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </SubLayout>
  );
}

export default MobilisationManagement;
